import Modal from "../Modal";
import {
  AuthTypeTextSC,
  BottomInnerWrapperSC,
  BottomWrapperSC,
  ButtonSC,
  ContentSC,
  ImgSC,
  InputWrapperSC,
  TextErrorSC,
  TextFieldSC,
  TextSecondarySC,
  TitleSC,
} from "./styled";
import { loginSchema } from "./schemaLogin";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import AuthBackground from "../../assets/icons/auth-background.jpg";
import { useAuthStore } from "../../stores/auth";
import { toast } from "react-toastify";
import { useModalStore } from "../../stores/modal";
import { useTranslation } from "react-i18next";

const ModalLogin = () => {
  const { userLogin, authErrors, clearAuthErrors } = useAuthStore();
  const { modal, setModal } = useModalStore();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: { email: "", password: "" },
  });

  const handleRegister = () => {
    reset();
    clearAuthErrors();
    setModal("register");
  };

  const handleForgotPassword = () => {
    reset();
    setModal("forgot-password");
  };

  const onSubmit = async (data) => {
    const { email, password } = data;
    const result = await userLogin(email, password);
    if (result) {
      toast.success(t("toast-success-log-in"));
      reset();
      setModal("");
    }
  };

  return (
    <Modal
      open={modal === "login"}
      setIsOpen={() => {
        reset();
        setModal("");
        clearAuthErrors();
      }}>
      <ImgSC src={AuthBackground} alt="" />
      <ContentSC>
        <TitleSC>{t("wellcome-back")}</TitleSC>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapperSC>
            <TextFieldSC id="email" name="email" label="Email" variant="standard" {...register("email")} />
            <TextErrorSC>
              {errors.email?.message === "mail-format"
                ? t("error-mail-format")
                : errors.email?.message === "mail-required" && t("error-mail-required")}
            </TextErrorSC>
          </InputWrapperSC>
          <InputWrapperSC>
            <TextFieldSC type="password" id="password" label="Password" variant="standard" {...register("password")} />
            <TextErrorSC>
              {errors.password?.message === "password-required" && t("error-password-required")}
            </TextErrorSC>
          </InputWrapperSC>
          <AuthTypeTextSC style={{ textAlign: "right" }} onClick={handleForgotPassword}>
            {t("forgot-password")}?
          </AuthTypeTextSC>
          <TextErrorSC style={{ textAlign: "center", marginTop: "20px" }}>
            {authErrors === "Credentials incorrect"
              ? t("error-credentials")
              : authErrors === "Unconfirmed email" && t("error-unconfirmed-email")}
          </TextErrorSC>
          <BottomWrapperSC>
            <BottomInnerWrapperSC>
              <TextSecondarySC>{t("log-in")}</TextSecondarySC>
              <AuthTypeTextSC onClick={handleRegister}>{t("sign-up")}</AuthTypeTextSC>
            </BottomInnerWrapperSC>
            <ButtonSC>
              <ArrowRightIcon />
            </ButtonSC>
          </BottomWrapperSC>
        </form>
      </ContentSC>
    </Modal>
  );
};

export default ModalLogin;
