import styled from "styled-components";

export const VotesWrapperSC = styled.div`
  display: flex;
  margin-top: 2px;
  padding: 4px;
  padding-left: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  .icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 80px;
  }
  .info {
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2px;
      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      h2 {
        font-size: 14px;
        font-weight: 600;
        margin-left: 4px;
        color: ${({ theme }) => theme.neutral3};
      }
      p {
        font-size: 12px;
        color: ${({ theme }) => theme.neutral2};
      }
    }
    .vote-info-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 8px;
      margin-top: 2px;

      h2 {
        font-size: 14px;
        color: ${({ theme }) => theme.neutral3};
        font-family: ${({ theme }) => theme.fontSub};
      }

      .flex {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        p {
          font-size: 12px;

          color: ${({ theme }) => theme.primary};
        }
        .coef {
          color: ${({ theme }) => theme.secondary};
        }
      }
    }
  }
`;

export const NoVotesSC = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.neutral1};
  margin-top: 8px;
`;
