import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ContainerSC } from "../../components/commonSC";
import { useAuthStore } from "../../stores/auth";
import { TextSC } from "./styled";

const VerifyEmail = () => {
  const { verifyEmail } = useAuthStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { t } = useTranslation();

  const handleVerify = async (token) => {
    const result = await verifyEmail(token);
    if (result === true) {
      toast.success(t("toast-success-account-verified"))
      navigate("/", { replace: true });
    } else {
      toast.error(t("toast-error-account-verified"));
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/", { replace: true });
    } else {
      handleVerify(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate]);

  return (
    <ContainerSC>
      <TextSC>{t("verifying-account")}</TextSC>
    </ContainerSC>
  );
};

export default VerifyEmail;
