import styled from "styled-components";

export const WrapperSC = styled.footer`
  margin-top: 140px;
  background-color: #212121;
  padding: 24px 0;

  @media (max-width: 1023px) {
    padding: 40px 0;
  }

  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  > * {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: space-between;
    }
  }
`;

export const LinksSC = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 80px;
    justify-content: space-evenly;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }

  .section {
    border-left: 1px solid white;
    text-transform: uppercase;
    padding-left: 16px;
    min-height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 450px) {
      border-left: none;
      align-items: center;
    }

    &:not(:last-of-type) {
      margin-right: 78px;

      @media (max-width: 450px) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    .item {
      color: #f5f7fa;
      font-size: 12px;
      font-weight: 500;
      font-family: ${({ theme }) => theme.fontOrbitron};
    }

    .contact-us {
      margin-bottom: 0;
      margin-top: auto;
    }

    .email {
      text-transform: none;
      text-decoration: underline;
    }

    .contact-us {
      margin-bottom: 0;
    }
  }
`;

export const LogosSC = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: space-evenly;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 32px;

      @media (max-width: 450px) {
        margin-right: 0px;
        margin-bottom: 52px;
      }
    }
  }

  .title {
    color: #f5f7fa;
    font-size: 8px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .image-wrapper {
    width: 94px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
