import styled from "styled-components";

import Modal from "@mui/material/Modal";

export const ModalSC = styled(Modal)`
  &.MuiModal-root {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;

export const ModalBackdropSC = styled.div`
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalBoxSC = styled.div`
  outline: none;
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  scrollbar-width: none !important;
  @media (max-width: 600px) {
    max-height: calc(100vh - 50px);
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    width: 100%;
  }
`;
