import styled from "styled-components";

export const TwitterWrapperSC = styled.div`
  max-height: 460px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.neutral1};

  @media (max-width: 1023px) {
    max-width: unset;
    margin-bottom: 30px;
  }
`;

export const TwitterSectionSC = styled.section`
  border: ${(props) => (props.theme === "#FFFFFF" ? "1px solid #D3D3D3" : "1px solid #1E4951")};
  max-height: 440px;
  overflow-y: scroll;
  margin-bottom: 40px;
  border-radius: 30px;
  padding: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TwitterATagSC = styled.a`
  &.twitter-timeline {
    text-align: center;
    padding: 16px;
  }
`;
