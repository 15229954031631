import React from "react";
import { useTheme } from "styled-components";
import { CommonWrapperSC } from "../../components/commonSC";
import CommonTitle from "../../components/commonTitle";
import { useAuthStore } from "../../stores/auth";
import { TaskWrapperSC } from "./Tasks.styled";

const Tasks = () => {
  const theme = useTheme();
  const { userDetails } = useAuthStore();

  return (
    <div style={{ flex: 1, flexBasis: "264px" }}>
      <CommonTitle title="tasks" shortName="T" color={theme.primary} />
      <CommonWrapperSC style={{ maxHeight: "220px" }}>
        <TaskWrapperSC>
          <div className="icon-wrapper">
            {userDetails?.role === "user" ? <div className="completed-icon" /> : <div className="hourglass-icon" />}
          </div>
          <div className="info">
            <div className="title">
              <h2>REGISTER TO VOTEZ.GG</h2>
              <p>+100VOTZ</p>
            </div>
            <div className="description">
              <p>
                Register to Votez.gg now to get 100 VOTZ on your account. Earn Unique BVadge "Pioneer BETA" as a
                prerelease member of Votez
              </p>
            </div>
          </div>
        </TaskWrapperSC>
      </CommonWrapperSC>
    </div>
  );
};

export default Tasks;
