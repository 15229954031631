import { Drawer as DrawerMUI } from "@mui/material";

const Drawer = ({ children, breakpoint, ...rest }) => {
  if (breakpoint) {
    return (
      <DrawerMUI {...rest} sx={{ zIndex: "1100" }}>
        {children}
      </DrawerMUI>
    );
  } else {
    return children;
  }
};

export default Drawer;
