import { ContainerSC } from "../../components/commonSC";
import { ContentWrapperSC, LeftWrapperSC, MainWrapperSC, RightWrapperSC } from "./index.styled";
import LiveBar from "./LiveBar";
import TwitterFeed from "./TwitterFeed";
import DiscordFeed from "./DiscordFeed";
import Leaderboard from "./Leaderboard";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import Tasks from "./Tasks";
import ReleaseInformation from "./ReleaseInformation";
import UpcommingMatches from "./UpcommingMatches";
import OngoingTournaments from "./OngoingTournaments";
import VoteLogs from "../../components/VoteLogs";
import { useVoteLogsStore } from "../../stores/voteLogs";
import { useAuthStore } from "../../stores/auth";

const Home = ({ theme }) => {
  const matches1024Up = useMediaQuery("(min-width:1024px)");
  const [series, setSeries] = useState(null);
  const { voteLogsFromFollowers, voteLogsFromTopUsers, getVoteLogsFromFollowers, getVoteLogsFromTopUsers } =
    useVoteLogsStore();
  const { token, isLoggedIn } = useAuthStore();

  const getSeries = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/match`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      let data = await response.json();

      if (response.ok) {
        setSeries(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSeries();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (series) {
      getVoteLogsFromFollowers(isLoggedIn, token, series[0]);
      getVoteLogsFromTopUsers(isLoggedIn, token, series[0]);
    }
    //eslint-disable-next-line
  }, [series]);

  return (
    <ContainerSC>
      <LiveBar series={series} />
      <MainWrapperSC>
        <LeftWrapperSC>
          <ContentWrapperSC>
            <Tasks />
            <ReleaseInformation />
          </ContentWrapperSC>
          {!matches1024Up && (
            <>
              <UpcommingMatches series={series} />
              <OngoingTournaments />
            </>
          )}
          <ContentWrapperSC>
            <VoteLogs
              title="vote-logs-from-users-you-follow"
              logs={voteLogsFromFollowers}
              isLoggedIn={isLoggedIn}
              shortName="FU"
            />
            <VoteLogs
              title="vote-logs-from-top-5-users"
              logs={voteLogsFromTopUsers}
              isLoggedIn={isLoggedIn}
              shortName="T5"
            />
          </ContentWrapperSC>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Leaderboard />
          </div>
        </LeftWrapperSC>
        <RightWrapperSC
          style={{
            flexGrow: !matches1024Up && 1,
          }}>
          {matches1024Up && (
            <>
              <UpcommingMatches series={series} />
              <OngoingTournaments />
            </>
          )}
          <TwitterFeed theme={theme} />
          <DiscordFeed theme={theme} />
        </RightWrapperSC>
      </MainWrapperSC>
    </ContainerSC>
  );
};

export default Home;
