import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Widget } from "@grid-esports/widget-sdk/react";

import VoteCard from "../../components/VoteCard/VoteCard";
import { ContainerSC } from "../../components/commonSC";
import { WrapperSC, ScoreboardWrapperSC } from "./styled";
import { toast } from "react-toastify";
import { useAuthStore } from "../../stores/auth";
import { useTranslation } from "react-i18next";
import VoteLogs from "../../components/VoteLogs";
import UserVoteLogs from "./UserVoteLogs";
import { useVoteLogsStore } from "../../stores/voteLogs";
import io from "socket.io-client";

const Game = () => {
  const { gameId } = useParams();
  const matches1024Up = useMediaQuery("(min-width:1024px)");
  const [userVoted, setUserVoted] = useState(false);
  const [gridToken, setGridToken] = useState(null);
  const { token, isLoggedIn, userDetails } = useAuthStore();
  const {
    voteLogsFromFollowers,
    voteLogsFromTopUsers,
    getVoteLogsFromFollowers,
    getVoteLogsFromTopUsers,
    matchVoteLogs,
    getMatchVoteLogs,
  } = useVoteLogsStore();
  const [match, setMatch] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  // const socket = useRef();
  // const [liveData, setLiveData] = useState(null);

  // useEffect(() => {
  //   socket.current = io("https://backend.grid.marrowlabs.com/match", { query: { matchId: "3" } });
  // }, []);

  // useEffect(() => {
  //   console.log(socket);
  //   console.log(socket.current.connected);
  //   if (socket.current.connected)
  //     socket.current.on("connect", () => {
  //       console.log("connected to server");
  //     });

  //   socket.current.on("onMatchChange", (data) => {
  //     console.log("data:", data);
  //     setLiveData(data);
  //   });
  // }, [socket]);

  const getMatch = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/match/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      let data = await response.json();

      if (data?.data?.response === "MATCH.NOT_FOUND") {
        setError(true);
        toast.error(t("toast-error-match"));
      } else {
        setMatch(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getToken = async (seriesId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/widget/video-token/${seriesId.toString()}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      let data = await response.json();

      if (response.ok) {
        setGridToken(data.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (match) {
      getMatchVoteLogs(isLoggedIn, match);
      getVoteLogsFromFollowers(isLoggedIn, token, match);
      getVoteLogsFromTopUsers(isLoggedIn, token, match);
    }
    if (userVoted) {
      setUserVoted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, isLoggedIn, userVoted]);

  useEffect(() => {
    if (gameId) {
      getMatch(gameId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  useEffect(() => {
    if (match.gridSeriesId) {
      getToken(match.gridSeriesId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.gridSeriesId]);

  return (
    <ContainerSC>
      {match && !error && (
        <>
          <ScoreboardWrapperSC>
            <div className="series-scoreboard">
              <Widget type="series-scoreboard" scope={{ type: "series", id: match.gridSeriesId }} />
            </div>
          </ScoreboardWrapperSC>
          <WrapperSC>
            <div className="main-column">
              <div className="video-stream">
                <Widget
                  type="stream"
                  scope={{ type: "series", id: match.gridSeriesId }}
                  options={{ token: gridToken }}
                />
              </div>
              {!matches1024Up && <VoteCard serieId={match.id} setUserVoted={setUserVoted} />}
              <div className="series-livelog">
                <Widget type="series-livelog" scope={{ type: "series", id: match.gridSeriesId }} />
              </div>
              {!matches1024Up && (
                <>
                  <UserVoteLogs
                    title="your-vote-logs-from-this-match"
                    shortName="UL"
                    logs={matchVoteLogs}
                    userId={userDetails?.id}
                    isLoggedIn={isLoggedIn}
                  />

                  <VoteLogs
                    title="Vote logs from users you follow"
                    logs={voteLogsFromFollowers}
                    userId={userDetails?.id}
                    isLoggedIn={isLoggedIn}
                    shortName="FU"
                  />

                  <VoteLogs
                    title="Vote logs from top 5 users"
                    logs={voteLogsFromTopUsers}
                    userId={userDetails?.id}
                    isLoggedIn={isLoggedIn}
                    shortName="T5"
                  />
                </>
              )}
              <div className="series-table">
                <Widget type="series-table" scope={{ type: "series", id: match.gridSeriesId }} />
              </div>
              <div className="series-comparison">
                <Widget type="series-comparison" scope={{ type: "series", id: match.gridSeriesId }} />
              </div>
              <div className="series-map-schematic">
                <Widget type="series-map-schematic" scope={{ type: "series", id: match.gridSeriesId }} />
              </div>

              {/* <div className="series-predictions-tracker">
            <Widget type="series-predictions-tracker" scope={{ type: "series", id: match.gridSeriesId }} />
          </div> */}
              <div className="series-team-scoreboard">
                <Widget type="series-team-scoreboard" scope={{ type: "series", id: match.gridSeriesId }} />
              </div>

              <div className="series-timeline">
                <Widget type="series-timeline" scope={{ type: "series", id: match.gridSeriesId }} />
              </div>
            </div>

            {matches1024Up && (
              <div className="aside-column">
                <VoteCard serieId={match.id} setUserVoted={setUserVoted} />
                <UserVoteLogs
                  title="your-vote-logs-from-this-match"
                  shortName="UL"
                  logs={matchVoteLogs}
                  userId={userDetails?.id}
                  isLoggedIn={isLoggedIn}
                />

                <VoteLogs
                  title="Vote logs from users you follow"
                  logs={voteLogsFromFollowers}
                  userId={userDetails?.id}
                  isLoggedIn={isLoggedIn}
                  shortName="FU"
                />

                <VoteLogs
                  title="Vote logs from top 5 users"
                  logs={voteLogsFromTopUsers}
                  userId={userDetails?.id}
                  isLoggedIn={isLoggedIn}
                  shortName="T5"
                />
              </div>
            )}
          </WrapperSC>
        </>
      )}
    </ContainerSC>
  );
};

export default Game;
