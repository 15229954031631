import * as yup from "yup";

export const twitterSchema = yup.object().shape({
  twitter: yup
    .string()
    .required("twitter-required")
    .matches("@", {
      message: "twitter-format",
      excludeEmptyString: true,
    })
    .min(3, "twitter-min-chars"),
});
