import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import CommonTitle from "../../components/commonTitle";
import { NoVotesSC, VotesWrapperSC } from "../../components/VoteLogs/index.styled";

const UserVoteLogs = ({ logs, userId, isLoggedIn, title, shortName }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const displayLogs = useMemo(() => {
    let computedLogs = logs;
    if (!computedLogs) computedLogs = [];

    return computedLogs.filter((log) => log.user.id === userId);
  }, [logs, userId]);

  return (
    <div style={{ flex: 1, maxHeight: "260px" }}>
      {title && <CommonTitle title={title} shortName={shortName} color={theme.primary} />}
      {isLoggedIn ? (
        displayLogs?.length === 0 && <NoVotesSC>{t("no-votes")}</NoVotesSC>
      ) : (
        <NoVotesSC>{t("not-logged-in-votes")}</NoVotesSC>
      )}
      {userId &&
        isLoggedIn &&
        displayLogs?.length > 0 &&
        displayLogs.map((log) => {
          return (
            <VotesWrapperSC key={log.id}>
              <div className="icon-wrapper">
                <img
                  src={require("../../assets/img/dota-bg.png")}
                  alt=""
                  style={{ width: "auto", height: "auto", maxWidth: "40px", maxHeight: "40px", objectFit: "contain" }}
                />
              </div>

              <div className="info">
                <div className="title">
                  <h2>Singapore</h2>
                  <p>{log.user.name}</p>
                </div>
                <div className="vote-info-wrapper">
                  <h2>{log.market.name}</h2>
                  <div className="flex">
                    <p className="vote-name">&lt;{log.name}&gt;</p>
                    <p className="coef">175</p>
                  </div>
                </div>
              </div>
            </VotesWrapperSC>
          );
        })}
    </div>
  );
};

export default UserVoteLogs;
