import { createGlobalStyle } from "styled-components";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import BarlowMedium from "../assets/fonts/Barlow-Regular.ttf";
import FuturaMedium from "../assets/fonts/Futura-Medium.ttf";
import OrbitronMedium from "../assets/fonts/Orbitron-Medium.ttf";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const muiOverrides = {
  fontMain: "Futura, Arial, sans-serif",
  fontSub: "Barlow, Arial, sans-serif",
  fontOrbitron: "Orbitron, Arial, sans-serif",
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          top: "10px",
          maxWidth: "200px",
          paddingTop: "12px",
          paddingBottom: "12px",
        },
        arrow: {},
      },
    },
  },
};

export const lightTheme = createTheme({
  body: "#FFFFFF",
  primary: "#63CCC0",
  secondary: "#2B4EFF",
  danger: "#FF2121",
  neutral1: "#D3D3D3",
  neutral2: "#B4AE9B",
  neutral3: "#1E4951",
  neutral4: "#1B2A31",
  ...muiOverrides,
});

export const darkTheme = createTheme({
  body: "#1B2A31",
  primary: "#63CCC0",
  secondary: "#2B4EFF",
  danger: "#FF2121",
  neutral1: "#1E4951",
  neutral2: "#B4AE9B",
  neutral3: "#CBD2D9",
  neutral4: "#F5F7FA",
  ...muiOverrides,
});

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Barlow';
    src: local('BarlowRegular'), local('BarlowRegular'),
    url(${BarlowMedium}) format('truetype')
  }

  @font-face {
    font-family: 'Futura';
    src: local('FuturaMedium'), local('FuturaMedium'),
    url(${FuturaMedium}) format('truetype')
  }
   
  @font-face {
    font-family: 'Orbitron';
    src: local('OrbitronRegular'), local('OrbitronRegular'),
    url(${OrbitronMedium}) format('truetype')
  }

  body {
    background: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.neutral3} !important;
  }

  h1, h2, h3, h4, h5, h6, p, span, div, button {
    font-family: ${({ theme }) => theme.fontMain};
    color: ${({ theme }) => theme.neutral3};
    margin: 0;
    padding: 0;
  }

  a {
    outline: none;
    text-decoration: none;
    color: ${({ theme }) => theme.neutral3};
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.neutral1};
    border: 1px solid transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.neutral2};
    border: ${({ theme }) => `1px solid ${theme.neutral3}`};
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: ${({ theme }) => theme.neutral3};
    border: ${({ theme }) => `1px solid ${theme.neutral3}`};
  }
  
  .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.neutral1} !important;
  }

  .MuiTooltip-arrow {
    color: ${({ theme }) => theme.neutral1} !important;
  }
`;

const StyleProvider = ({ children, theme }) => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme={theme}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <SCThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <CssBaseline />
          <GlobalStyle />
          {children}
        </SCThemeProvider>
      </ThemeProvider>
    </>
  );
};

export default StyleProvider;
