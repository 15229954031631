import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLeaderboardStore } from "../../stores/leaderboard";
import { Pagination, Table, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { ContainerSC, NoIconSC } from "../../components/commonSC";
import { NoUsersTextSC, PaginationStackSC, TableBodySC, TableSectionSC, TitleSC } from "./styled";
import { useTranslation } from "react-i18next";
import CommonTitle from "../../components/commonTitle";
import { useTheme } from "styled-components";

const Leaderboard = () => {
  const theme = useTheme();
  const matches1024Up = useMediaQuery("(min-width:1024px)");
  const matches768Down = useMediaQuery("(max-width:768px)");
  const { users, getUsers, totalUsersCount, usersPerPage, getBottom5Users, bottom5Users } = useLeaderboardStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getUsers(usersPerPage, 0);
    getBottom5Users();
  }, [getUsers, usersPerPage, getBottom5Users]);

  const handleChange = async (event, value) => {
    await getUsers(usersPerPage, usersPerPage * (value - 1));
  };

  const handleRowClick = (id) => {
    navigate(`/user/${id}`);
  };

  return (
    <ContainerSC>
      <TitleSC>{t("user-leaderboard")}</TitleSC>
      {users && (
        <>
          <CommonTitle title="hall-of-fame" shortName="HF" color={theme.primary} />
          <TableSectionSC style={{ marginBottom: "60px" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("rank")}</TableCell>
                    <TableCell>{t("player")}</TableCell>
                    {matches1024Up && <TableCell>{t("specials")}</TableCell>}
                    {!matches768Down && <TableCell>{t("level")}</TableCell>}
                    <TableCell>{t("win-ratio")}</TableCell>
                    {matches1024Up && (
                      <>
                        <TableCell>{t("total-wins")}</TableCell>
                        <TableCell>{t("biggest-streak")}</TableCell>
                        <TableCell>{t("current-streak")}</TableCell>
                      </>
                    )}
                    <TableCell>{t("currency")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBodySC>
                  {users.map((item, index) => {
                    return (
                      <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
                        <TableCell>
                          <div className="rank">{item.rank}</div>
                        </TableCell>
                        <TableCell>
                          <div className="image-name">
                            {item.avatar ? (
                              <img
                                className="image"
                                src={require(`../../assets/icons/hero-avatars/${item.avatar}`)}
                                alt="Avatar"
                              />
                            ) : (
                              <NoIconSC size="28" style={{ marginRight: "16px" }}>
                                <div className="no-icon" />
                              </NoIconSC>
                            )}
                            <span className="name">{item.name}</span>
                          </div>
                        </TableCell>
                        {matches1024Up && <TableCell>-</TableCell>}
                        {!matches768Down && <TableCell className="level">Level {item.level}</TableCell>}
                        <TableCell>{item.voteStatistics.winPercentage}%</TableCell>
                        {matches1024Up && (
                          <>
                            <TableCell>{item.voteStatistics.numberOfWins}</TableCell>
                            <TableCell>{item.voteStatistics.biggestWinStreak}</TableCell>
                            <TableCell>{item.voteStatistics.winStreak}</TableCell>
                          </>
                        )}
                        <TableCell className="buffs">{item.buffs}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBodySC>
              </Table>
              {users.length === 0 && <NoUsersTextSC>There are currently no users</NoUsersTextSC>}
            </TableContainer>
            {users?.length < totalUsersCount && (
              <PaginationStackSC>
                <Pagination
                  count={Math.ceil(totalUsersCount / usersPerPage)}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </PaginationStackSC>
            )}
          </TableSectionSC>
        </>
      )}

      {totalUsersCount >= usersPerPage + 5 && (
        <>
          <CommonTitle title="hall-of-shame" shortName="HS" color={theme.primary} />
          <TableSectionSC>
            {bottom5Users && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("rank")}</TableCell>
                      <TableCell>{t("player")}</TableCell>
                      {matches1024Up && <TableCell>{t("specials")}</TableCell>}
                      {!matches768Down && <TableCell>{t("level")}</TableCell>}
                      <TableCell>{t("win-ratio")}</TableCell>
                      {matches1024Up && (
                        <>
                          <TableCell>{t("total-wins")}</TableCell>
                          <TableCell>{t("biggest-streak")}</TableCell>
                          <TableCell>{t("current-streak")}</TableCell>
                        </>
                      )}
                      <TableCell>{t("currency")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBodySC>
                    {bottom5Users.map((item, index) => {
                      return (
                        <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
                          <TableCell>
                            <div className="rank">{item.rank}</div>
                          </TableCell>
                          <TableCell>
                            <div className="image-name">
                              {item.avatar ? (
                                <img
                                  className="image"
                                  src={require(`../../assets/icons/hero-avatars/${item.avatar}`)}
                                  alt="Avatar"
                                />
                              ) : (
                                <NoIconSC size="28" style={{ marginRight: "16px" }}>
                                  <div className="no-icon" />
                                </NoIconSC>
                              )}
                              <span className="name">{item.name}</span>
                            </div>
                          </TableCell>
                          {matches1024Up && <TableCell>-</TableCell>}
                          {!matches768Down && <TableCell className="level">Level {item.level}</TableCell>}
                          <TableCell>{item.voteStatistics.winPercentage}%</TableCell>
                          {matches1024Up && (
                            <>
                              <TableCell>{item.voteStatistics.numberOfWins}</TableCell>
                              <TableCell>{item.voteStatistics.biggestWinStreak}</TableCell>
                              <TableCell>{item.voteStatistics.winStreak}</TableCell>
                            </>
                          )}
                          <TableCell className="buffs">{item.buffs}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBodySC>
                </Table>
              </TableContainer>
            )}
          </TableSectionSC>
        </>
      )}
    </ContainerSC>
  );
};

export default Leaderboard;
