import styled from "styled-components";
import { TableBody } from "@mui/material";

export const SubtitleSC = styled.h2`
  font-size: 36px;
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 64px;
`;

export const TableSectionSC = styled.div`
  padding: 0px 22px;
  border-radius: 20px;

  .MuiTableHead-root {
    .MuiTableCell-root {
      font-size: 12px;
      line-height: 44px;
      font-weight: 500;
      color: ${({ theme }) => theme.neutral4};
      white-space: nowrap;
      padding: 0;
      padding-right: 8px;
      border-bottom: 0;
      font-family: ${({ theme }) => theme.fontOrbitron};
      &.first {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
`;

export const TableBodySC = styled(TableBody)`
  .MuiTableRow-root {
    cursor: pointer;
  }

  .MuiTableCell-root {
    height: 44px;
    font-size: 12px;
    color: ${({ theme }) => theme.neutral4};
    padding: 0;
    padding-right: 8px;
    border-bottom: 0;
    font-weight: 500;
    white-space: nowrap;
    font-family: ${({ theme }) => theme.fontOrbitron};

    .name {
      font-family: ${({ theme }) => theme.fontOrbitron};
      font-size: 12px;
      color: ${({ theme }) => theme.neutral4};
      text-transform: uppercase;
    }
  }

  .first {
    display: flex;
    align-items: center;
  }

  .rank {
    background-color: ${({ theme }) => theme.primary};
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 16px;
  }

  .image-name {
    display: flex;
    align-items: center;

    .image {
      width: 28px;
      height: 28px;
      border-radius: 50px;
      border: ${({ theme }) => `2px solid ${theme.primary}`};
      object-fit: cover;
      margin-right: 16px;
    }
  }

  .level {
    font-size: 12px;
    color: ${({ theme }) => theme.neutral2};
    font-family: ${({ theme }) => theme.fontMain};
  }

  .buffs {
    color: ${({ theme }) => theme.primary};
    font-family: ${({ theme }) => theme.fontOrbitron};
  }
`;
