import styled from "styled-components";
import { TableBody } from "@mui/material";

export const TitleSC = styled.h1`
  font-size: 36px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontMain};
  text-transform: uppercase;
  line-height: 45px;
  padding-top: 105px;
  padding-bottom: 64px;
`;

export const SubtitleSC = styled.h2`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const NoUsersTextSC = styled.h2`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontSub};
  color: ${({ theme }) => theme.neutral1};
  margin-top: 8px;
`;

export const TableSectionSC = styled.div`
  padding-bottom: 24px;

  .MuiTableHead-root {
    .MuiTableCell-root {
      font-size: 12px;
      line-height: 44px;
      color: ${({ theme }) => theme.neutral4};
      text-transform: uppercase;
      padding: 0;
      padding-right: 8px;
      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      font-family: ${({ theme }) => theme.fontOrbitron};
      white-space: nowrap;
    }
  }
`;

export const TableBodySC = styled(TableBody)`
  .MuiTableRow-root {
    cursor: pointer;
  }

  .MuiTableCell-root {
    height: 44px;
    font-size: 12px;
    color: ${({ theme }) => theme.neutral4};
    padding: 0;
    padding-right: 8px;
    border-bottom: 0;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontMain};
    white-space: nowrap;
    font-family: ${({ theme }) => theme.fontOrbitron};

    .name {
      font-family: ${({ theme }) => theme.fontOrbitron};
      font-size: 12px;
      color: ${({ theme }) => theme.neutral4};
    }
  }

  .rank {
    background-color: ${({ theme }) => theme.primary};
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-name {
    display: flex;
    align-items: center;

    .image {
      width: 28px;
      height: 28px;
      border-radius: 50px;
      border: ${({ theme }) => `2px solid ${theme.primary}`};
      object-fit: cover;
      margin-right: 16px;
    }
  }

  .level {
    font-family: ${({ theme }) => theme.fontMain};
    color: ${({ theme }) => theme.neutral2};
    text-transform: unset;
  }

  .buffs {
    color: ${({ theme }) => theme.primary};
    font-family: ${({ theme }) => theme.fontOrbitron};
  }
`;

export const PaginationStackSC = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 62px;

  .MuiButtonBase-root {
    color: ${({ theme }) => theme.primary};
    font-family: ${({ theme }) => theme.fontMain};
    font-size: 12px;
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};
    margin: 0 2px;

    &.Mui-selected,
    &:hover {
      color: ${({ theme }) => theme.body};
      background-color: ${({ theme }) => theme.primary};

      &.Mui-selected {
        color: ${({ theme }) => theme.body};
        background-color: ${({ theme }) => theme.primary};
      }
    }
  }

  .MuiPaginationItem-ellipsis {
    color: ${({ theme }) => theme.primary};
    margin: 12px 2px 0 2px;
  }
`;
