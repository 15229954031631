import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WrapperSC, CardWrapperSC, LiveBarWrapperSC, LiveBarTitle, OuterWrapperSC } from "./LiveBar.styled";

const LiveBar = ({ series }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filteredSeries, setFilteredSeries] = useState([]);
  const handleCardClick = (id) => {
    navigate(`/game/${id}`);
  };

  useEffect(() => {
    if (series?.length > 0) {
      setFilteredSeries(series.filter((serie) => serie.isLive === true));
    }
  }, [series]);

  return (
    <OuterWrapperSC>
      <WrapperSC>
        <LiveBarTitle>
          <h2 className="live">{t("live").toUpperCase()}</h2>
          <p className="center">{t("center")}</p>
        </LiveBarTitle>
        <LiveBarWrapperSC>
          {filteredSeries?.length === 0 && <p className="no-games">There are currently no live games.</p>}
          {filteredSeries?.length > 0 &&
            filteredSeries.map((serie) => {
              return (
                <CardWrapperSC key={serie.id} onClick={() => handleCardClick(serie.id)}>
                  <div className="tournament-wrapper">
                    <p>{serie.tournament.shortName}</p>
                  </div>
                  <div className="info-wrapper">
                    <div className="info">
                      <img
                        src={require("../../assets/img/dota2logo.png")}
                        alt=""
                        style={{
                          width: "auto",
                          height: "auto",
                          maxWidth: "40px",
                          maxHeight: "40px",
                          objectFit: "contain",
                          marginLeft: "4px",
                        }}
                      />
                      <div className="team-info-wrapper">
                        {serie.matchTeams.map((team) => {
                          return (
                            <div className="team-info" key={team.team.id}>
                              <img src={team?.team.logoUrl} alt="" style={{ width: "20px", height: "20px" }} />
                              <p className="team-name"> {team.team.name}</p>
                              <p className="team-score">0</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </CardWrapperSC>
              );
            })}
        </LiveBarWrapperSC>
      </WrapperSC>
    </OuterWrapperSC>
  );
};

export default LiveBar;
