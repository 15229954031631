import styled from "styled-components";

export const WrapperSC = styled.div`
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  max-width: 500px;
  margin: auto;
  margin-top: 200px;
`;

export const ButtonSubmitSC = styled.button`
  margin: auto;
  margin-top: 40px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`;
