import styled from "styled-components";

export const OngoingWrapperSC = styled.div`
  display: flex;
  margin-top: 2px;
  padding: 4px;
  padding-left: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  .icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.neutral1};
    width: 100%;
    max-width: 64px;
  }
  .info {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      h2 {
        font-size: 14px;
        font-weight: 400;
        margin-left: 4px;
        color: ${({ theme }) => theme.neutral4};
      }
      p {
        font-size: 14px;
        color: ${({ theme }) => theme.neutral3};
      }
    }
    .tournament-info-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      margin-top: 4px;
      .inner {
        display: flex;
        align-items: center;
        gap: 4px;
        h2 {
          font-size: 12px;
          font-weight: 400;
          margin-left: 4px;
          color: ${({ theme }) => theme.neutral4};
        }
        p {
          flex: 1;
          text-align: end;
          font-size: 12px;
          color: ${({ theme }) => theme.neutral3};
        }
        .prize {
          font-size: 11px;
          color: ${({ theme }) => theme.neutral3};
          margin-left: 0;
        }
        .tier {
          font-size: 11px;
          color: ${({ theme }) => theme.neutral2};
        }
      }
    }
  }
`;
