import { TwitterWrapperSC } from "./TwitterFeed.styled";
import { Timeline } from "react-twitter-widgets";

const TwitterFeed = ({ theme }) => {
  return (
    <TwitterWrapperSC>
      <Timeline
        dataSource={{ sourceType: "profile", screenName: "dota2ti" }}
        options={{ chrome: "transparent", theme: theme === "light" ? "light" : "dark" }}
        dataChrome="transparent"
      />
    </TwitterWrapperSC>
  );
};

export default TwitterFeed;

// const TwitterFeed = () => {
//   const theme = useTheme();
//   const [themeColor, setthemeColor] = useState(theme.body);
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://platform.twitter.com/widgets.js";
//     document.getElementsByClassName("twitter-embed")[0].appendChild(script);
//   }, []);

//   const displayData = useMemo(() => {
//     return (
//       <TwitterSectionSC theme={themeColor}>
//         <div className="twitter-embed" style={{ border: "0px solid red" }}>
//           <TwitterATagSC
//             className="twitter-timeline"
//             data-theme={themeColor === "#FFFFFF" ? "light" : "dark"}
//             data-tweet-limit="15"
//             data-chrome="transparent nofooter noborders noheader noscrollbar"
//             href="https://twitter.com/dota2ti">
//             Tweets by @dota2ti
//           </TwitterATagSC>
//         </div>
//       </TwitterSectionSC>
//     );
//   }, [themeColor]);

//   useEffect(() => {
//     setthemeColor(theme.body);
//   }, [theme]);

//   return <>{displayData}</>;
// };
