import styled from "styled-components";

export const WrapperSC = styled.div`
  border-bottom: ${({ color }) => `1px solid ${color}`};
  display: flex;
  align-items: center;
  gap: 4px;
  h1 {
    font-size: 20px;
    font-weight: 600;
    color: ${({ color }) => color};
  }
`;
export const InnerTitleSC = styled.div`
  background-color: ${({ color }) => color};
  width: fit-content;
  padding: 0px 20px;
  p {
    font-size: 24px;
    font-weight: 800;
    color: ${({ theme }) => theme.neutral1};
  }
`;
