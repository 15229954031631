import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { CommonWrapperSC } from "../../components/commonSC";
import CommonTitle from "../../components/commonTitle";
import { InformationWrapperSC } from "./ReleaseInformation.styled";
import { format } from "date-fns";
import ReleaseInformationModal from "./ReleaseInformationModal";

const ReleaseInformation = () => {
  const theme = useTheme();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({ isOpen: false });

  const getNews = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/news`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      let data = await response.json();

      if (response.ok) {
        setNews(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Get news:", error);
    }
  };

  useEffect(() => {
    getNews();
    //eslint-disable-next-line
  }, []);

  return (
    <div style={{ flex: 1, flexBasis: "264px" }}>
      <CommonTitle title="release-information" shortName="RI" color={theme.primary} />
      {news?.length === 0 && !loading && <p>There are currently no news.</p>}
      <CommonWrapperSC style={{ maxHeight: "220px" }}>
        {news.length > 0 &&
          news.map((item) => {
            return (
              <InformationWrapperSC
                key={item.id}
                onClick={() => {
                  setModalInfo({ ...item, isOpen: true });
                }}>
                <div className="icon-wrapper">
                  <img
                    src={require("../../assets/img/logoIcon.png")}
                    alt=""
                    style={{ height: "inherit", width: "inherit", objectFit: "contain" }}
                  />
                </div>
                <div className="info">
                  <div className="title">
                    <h2>{item.title.length > 22 ? item.title.slice(0, 22).concat("...") : item.title}</h2>
                    <p> {format(new Date(item.createdAt), "dd.LL. HH:mm")}</p>
                  </div>
                  <div className="description">
                    <p>
                      {item.description.length > 140 ? item.description.slice(0, 140).concat("...") : item.description}
                    </p>
                  </div>
                </div>
              </InformationWrapperSC>
            );
          })}
      </CommonWrapperSC>
      <ReleaseInformationModal modalInfo={modalInfo} setIsOpen={setModalInfo} />
    </div>
  );
};

export default ReleaseInformation;
