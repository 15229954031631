import { useAuthStore } from "../../stores/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../components/Modal";
import { ContentSC, InputWrapperSC, SubmitButtonSC, TextErrorSC, TextFieldSC, TitleSC } from "./styled";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { twitterSchema } from "./schemaTwitter";
import { useTranslation } from "react-i18next";

const ModalAddTwitter = ({ isOpen, setIsOpen }) => {
  const { token, userDetails, updateUserDetails } = useAuthStore();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(twitterSchema),
    defaultValues: { twitter: userDetails?.twitter },
  });

  const onSubmit = async (data) => {
    const { twitter } = data;
    const result = await updateUserDetails(token, userDetails.avatar, userDetails.buffs, twitter);
    if (result) {
      toast.success(t("toast-success-update-user"));
    }
    reset();
    setIsOpen(false);
  };

  const handleRemove = async () => {
    const result = await updateUserDetails(token, userDetails.avatar, userDetails.buffs, "");
    if (result) {
      toast.success(t("toast-success-update-user"));
    }
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      setIsOpen={() => {
        reset();
        setIsOpen();
      }}>
      <ContentSC>
        <TitleSC>{t("edit-twitter")}</TitleSC>
        <form>
          <InputWrapperSC>
            <TextFieldSC
              id="twitter"
              name="twitter"
              label="Twitter link"
              variant="standard"
              placeholder="@user"
              {...register("twitter")}
            />
            <TextErrorSC>{errors.twitter?.message}</TextErrorSC>
          </InputWrapperSC>
        </form>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SubmitButtonSC onClick={handleRemove}>{t("remove")}</SubmitButtonSC>
          <SubmitButtonSC onClick={handleSubmit(onSubmit)}>{t("submit")}</SubmitButtonSC>
        </div>
      </ContentSC>
    </Modal>
  );
};

export default ModalAddTwitter;
