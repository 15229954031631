import styled from "styled-components";

export const ScoreboardWrapperSC = styled.div`
  padding-top: 30px;
  .series-scoreboard {
    iframe {
      /* height: 300px !important; */
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
`;

export const WrapperSC = styled.div`
  display: flex;

  .main-column {
    width: 100%;
    margin-right: 20px;

    @media (max-width: 1023px) {
      margin-right: 0;
    }
  }

  .aside-column {
    min-width: 440px;
    display: flex;
    flex-direction: column;
    margin-top: -40px;
  }

  // Widgets
  .video-stream {
    iframe {
      height: 500px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: -10px;
    }
  }

  .series-table {
    iframe {
      height: 330px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  .series-predictions-tracker {
    iframe {
      height: 150px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  .series-team-scoreboard {
    iframe {
      height: 280px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  .series-livelog {
    iframe {
      height: 300px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  .series-timeline {
    iframe {
      height: 210px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  .series-comparison {
    iframe {
      height: 300px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  .series-map-schematic {
    iframe {
      height: 300px !important;
      border-radius: 30px !important;
    }

    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
`;

export const InputWrapperSC = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  input {
    color: ${({ theme }) => theme.neutral4};
    background-color: ${({ theme }) => theme.body};
    border: ${({ theme }) => `1px solid ${theme.primary}`};
    outline: none;
    padding: 8px 20px;

    &::placeholder {
      color: ${({ theme }) => theme.neutral2};
    }
  }

  button {
    color: ${({ theme }) => theme.neutral1};
    background-color: ${({ theme }) => theme.primary};
    outline: none;
    border: none;
    padding: 10px 20px;
    margin-left: 20px;
  }
`;

export const ParagraphTextSC = styled.p`
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.neutral3};
  padding: 0;
  margin-bottom: 40px;
`;
