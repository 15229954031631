import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputWrapperSC, TextErrorSC, TextFieldSC } from "../../components/Auth/styled";
import { ContainerSC } from "../../components/commonSC";
import { useAuthStore } from "../../stores/auth";
import { resetPasswordSchema } from "./schemaResetPassword";
import { ButtonSubmitSC, WrapperSC } from "./styled";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { resetPassword } = useAuthStore();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: { password: "", confirmPassword: "" },
  });
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/", { replace: true });
    }
  }, [token, navigate]);

  const onSubmit = async (data) => {
    const { password } = data;
    const result = await resetPassword(password, token);
    if (result?.success) {
      toast.success(t("toast-success-reset-password"));
      reset();
      navigate("/", { replace: true });
    } else if (!result?.success) {
      toast.error(t("toast-error-reset-password"));
      reset();
      navigate("/", { replace: true });
    }
  };

  return (
    <ContainerSC>
      <WrapperSC>
        <h1>{t("reset-password")}</h1>
        <form>
          <InputWrapperSC>
            <TextFieldSC
              type="password"
              id="password"
              name="password"
              label="Password"
              variant="standard"
              {...register("password")}
            />
            <TextErrorSC>
              {errors.password?.message === "password-required"
                ? t("error-password-required")
                : errors.password?.message === "password-format" && t("error-password-format")}
            </TextErrorSC>
          </InputWrapperSC>
          <InputWrapperSC>
            <TextFieldSC
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              variant="standard"
              {...register("confirmPassword")}
            />
            <TextErrorSC>
              {errors.confirmPassword?.message === "password-mismatch" && t("error-password-mismatch")}
            </TextErrorSC>
          </InputWrapperSC>
          <ButtonSubmitSC onClick={handleSubmit(onSubmit)}>{t("submit")}</ButtonSubmitSC>
        </form>
      </WrapperSC>
    </ContainerSC>
  );
};

export default ResetPassword;
