import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { CommonWrapperSC } from "../../components/commonSC";
import CommonTitle from "../../components/commonTitle";
import { OngoingWrapperSC } from "./OngoingTournaments.styled";
import { format } from "date-fns";

const OngoingTournaments = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [runningTournaments, setRunningTournaments] = useState([]);
  const [allTournaments, setAllTournaments] = useState([]);

  const getOngoingTournaments = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          authorization: `Bearer ${process.env.REACT_APP_PANDASCORE_API_KEY}`,
        },
      };

      fetch("https://api.pandascore.co/tournaments/upcoming?per_page=100&filter[tier]=a&sort=begin_at", options)
        .then((response) => response.json())
        .then((response) => setData(response))
        .catch((err) => console.error(err));
    } catch (error) {
      console.log("getMatchError", error);
    }
  };

  const getRuningTournaments = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          authorization: `Bearer ${process.env.REACT_APP_PANDASCORE_API_KEY}`,
        },
      };

      fetch("https://api.pandascore.co/tournaments/running?per_page=100&filter[tier]=s&sort=begin_at", options)
        .then((response) => response.json())
        .then((response) => setRunningTournaments(response))
        .catch((err) => console.error(err));
    } catch (error) {
      console.log("getMatchError", error);
    }
  };
  const getAllTournaments = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          authorization: `Bearer ${process.env.REACT_APP_PANDASCORE_API_KEY}`,
        },
      };

      fetch("https://api.pandascore.co/tournaments/past?&per_page=30&filter[tier]=s", options)
        .then((response) => response.json())
        .then((response) => setAllTournaments(response))
        .catch((err) => console.error(err));
    } catch (error) {
      console.log("getMatchError", error);
    }
  };

  useEffect(() => {
    getOngoingTournaments();
    getRuningTournaments();
    getAllTournaments();
    //eslint-disable-next-line
  }, []);

  const displayData = useMemo(() => {
    let computedArray = [];
    if (data && runningTournaments && allTournaments)
      computedArray = [...data, ...runningTournaments, ...allTournaments];

    return computedArray.filter(
      (item) => (item.videogame.slug === "dota-2" || item.videogame.slug === "cs-go") && item.tier === "s",
    );
  }, [data, runningTournaments, allTournaments]);

  return (
    <div style={{ flex: 1 }}>
      <CommonTitle title="ongoing-tournaments" shortName="OT" color={theme.neutral4} />
      <CommonWrapperSC style={{ maxHeight: "260px" }}>
        {displayData?.length === 0 && <p className="no-games">There are currently no live tournaments.</p>}
        {displayData?.length > 0 &&
          displayData.map((item) => {
            return (
              <OngoingWrapperSC key={item.id}>
                <div className="icon-wrapper">
                  <img
                    src={
                      item.videogame.slug === "cs-go"
                        ? require("../../assets/img/cs-go-icon.png")
                        : require("../../assets/img/dota2logo.png")
                    }
                    alt=""
                    style={{ width: "auto", height: "auto", maxWidth: "44px", maxHeight: "30px", objectFit: "contain" }}
                  />
                  <p>{item.videogame.name}</p>
                </div>

                <div className="info">
                  <div className="title">
                    <h2>Singapore</h2>
                    <p>{item.serie.name ? item.serie.name : "Offline"}</p>
                  </div>
                  <div className="tournament-info-wrapper">
                    <div className="inner">
                      <img
                        src={
                          item.league.image_url ? item.league.image_url : require("../../assets/img/fallbackImage.png")
                        }
                        alt=""
                        style={{ width: "16px", height: "16px" }}
                      />
                      <h2>
                        {item.league.name} - {item.name}
                      </h2>
                      <p>
                        {item.begin_at && format(new Date(item.begin_at), "LLL dd")} -{" "}
                        {item.end_at ? format(new Date(item.end_at), "dd") : "unkown"}
                      </p>
                    </div>
                    <div className="inner">
                      <h2 className="prize">{item.prizepool ? item.prizepool : "Prize pool uknown"}</h2>
                      <p className="tier">Tier {item.tier.toUpperCase()}</p>
                    </div>
                  </div>
                </div>
              </OngoingWrapperSC>
            );
          })}
      </CommonWrapperSC>
    </div>
  );
};

export default OngoingTournaments;
