import create from "zustand";

export const useLeaderboardStore = create((set) => ({
  isLoadingUsers: false,
  isLoadingTop5Users: false,
  isLoadingBottom5Users: false,
  totalUsersCount: null,
  usersPerPage: 10,
  users: null,
  top5Users: null,
  bottom5Users: null,
  errorsUsers: null,
  errorsTop5Users: null,
  errorsBottom5Users: null,

  getTop5Users: async () => {
    try {
      set({ isLoadingTop5Users: true, errorsTop5: null });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/leaderboard?take=5&skip=0&orderBy=desc`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const responseJson = await response.json();
        set({ errorsTop5: responseJson.message });
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      set({ isLoadingTop5Users: false, top5Users: responseJson.users, totalUsersCount: responseJson.count });
    } catch (error) {
      console.log("leaderboard top 5 error: ", error);
      set({ isLoadingTop5Users: false });
    }
  },

  getBottom5Users: async () => {
    try {
      set({ isLoadingBottom5Users: true, errorsTop5: null });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/leaderboard?take=5&skip=0&orderBy=asc`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const responseJson = await response.json();
        set({ errorsBottom5: responseJson.message });
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      set({ isLoadingBottom5Users: false, bottom5Users: responseJson.users, totalUsersCount: responseJson.count });
    } catch (error) {
      console.log("leaderboard bottom 5 error: ", error);
      set({ isLoadingBottom5Users: false });
    }
  },

  getUsers: async (take, skip) => {
    try {
      set({ isLoadingUsers: true, authErrors: null });

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/leaderboard?take=${take}&skip=${skip}&orderBy=desc`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      if (!response.ok) {
        const responseJson = await response.json();
        set({ errorsUsers: responseJson.message });
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      set({ isLoadingUsers: false, users: responseJson.users, totalUsersCount: responseJson.count });
    } catch (error) {
      console.log("leaderboard users error: ", error);
      set({ isLoadingUsers: false });
    }
  },
}));
