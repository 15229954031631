import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useDarkMode = () => {
  const [theme, setTheme] = useState("light");
  const [checkedTheme, setCheckedTheme] = useState(false);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const setMode = (mode) => {
    window.localStorage.setItem("grid-theme", mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    theme === "light" ? setMode("dark") : setMode("light");
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("grid-theme");
    if (localTheme) {
      setTheme(localTheme);
    } else if (prefersDarkMode) {
      setMode("dark");
    } else {
      setMode("light");
    }
    setCheckedTheme(true);
  }, [prefersDarkMode]);

  return [theme, toggleTheme, checkedTheme];
};
