import { Pagination, Table, TableCell, TableContainer, TableRow } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContainerSC } from "../../components/commonSC";
import { PaginationStackSC } from "../Leaderboard/styled";
import { TableBodySC, TableSectionSC, TitleSC } from "./styled";

const Tournament = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  // eslint-disable-next-line
  const [prevPage, setPrevPage] = useState(
    "JAMWBQMjHhoSJBQfEhMCGxITCwtaCwtFRUVBQkVBCwtaCwtFR0VFWkZHWkZEI0dBTUdOTUNHLQ==",
  );
  const [firstPage, setFirstPage] = useState(false);

  const getTournament = async (cursor) => {
    try {
      var cursorAfter = "";

      if (firstPage === false) {
        cursorAfter = prevPage;
        setFirstPage(true);
      } else {
        cursorAfter = cursor;
        setFirstPage(false);
      }

      if (cursorAfter) {
        const response = await fetch("https://api.grid.gg/central-data/graphql", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "x-api-key": `${process.env.REACT_APP_AUTH_KEY}`,
          },
          body: JSON.stringify({
            variables: {
              cursorAfter,
            },
            query: `
          query GetAllSeriesInNext24Hours($cursorAfter: Cursor) {
            allSeries(
              filter:{
                startTimeScheduled:{
                  gte: "2022-10-18T00:05:39+01:00",
                  lte: "2023-10-30T00:05:39+01:00"
                },
                tournamentId: 106802
              },

              first:50,
              after: $cursorAfter,
              orderBy: StartTimeScheduled
            ) {
              totalCount,
              pageInfo{
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
              edges{
                cursor
                node{
                  ...allSeriesFields
                }
              }
            }
          }
          
          fragment allSeriesFields on Series {
            id
            title {
              nameShortened
              name
            }
            tournament {
              nameShortened
              name
              id
              logoUrl
            }
            startTimeScheduled
            format {
              name
              nameShortened
            }
            teams {
              baseInfo {
                name
                logoUrl
                colorPrimary
                colorSecondary
              }
              scoreAdvantage
            }
          }
            `,
          }),
        });
        let data = await response.json();

        if (data) {
          setData(data.data);
        }
      }
    } catch (error) {
      console.log("getTournamentError", error);
    }
  };

  useEffect(() => {
    getTournament();
    //eslint-disable-next-line
  }, []);

  return (
    <ContainerSC>
      <TitleSC>{t("schedule")}</TitleSC>
      {data && (
        <TableSectionSC>
          <TableContainer>
            <Table>
              <TableBodySC>
                {data.allSeries.edges.map((item) => {
                  return (
                    <TableRow key={item.node.id}>
                      <TableCell className="title">
                        <div>{item.node.title.nameShortened}</div>
                      </TableCell>
                      <TableCell className="time">
                        <div>{format(parseISO(item.node.startTimeScheduled), "yyyy-MM-dd h:mm a")}</div>
                      </TableCell>
                      <TableCell className="teams">
                        <div className="team1">
                          <span className="name" style={{ color: item.node.teams[0].baseInfo.colorPrimary }}>
                            {item.node.teams[0].baseInfo.name}
                          </span>
                          <div
                            className="icon"
                            style={{ border: `1px solid ${item.node.teams[0].baseInfo.colorPrimary}` }}>
                            <img src={item.node.teams[0].baseInfo.logoUrl} alt="" />
                          </div>
                        </div>
                        <div className="vs">x</div>
                        <div className="team2">
                          <div
                            className="icon"
                            style={{ border: `1px solid ${item.node.teams[1].baseInfo.colorPrimary}` }}>
                            <img src={item.node.teams[1].baseInfo.logoUrl} alt="" />
                          </div>
                          <span className="name" style={{ color: item.node.teams[1].baseInfo.colorPrimary }}>
                            {item.node.teams[1].baseInfo.name}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className="tournament">
                        <div>{item.node.tournament.name}</div>
                      </TableCell>
                      <TableCell className="format">
                        <div>{item.node.format.nameShortened}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBodySC>
            </Table>
          </TableContainer>
          {true && (
            <PaginationStackSC>
              <Pagination
                variant="outlined"
                shape="rounded"
                onChange={() => data.allSeries.pageInfo.hasNextPage && getTournament(data.allSeries.pageInfo.endCursor)}
              />
            </PaginationStackSC>
          )}
        </TableSectionSC>
      )}
    </ContainerSC>
  );
};

export default Tournament;
