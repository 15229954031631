import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { CommonWrapperSC } from "../commonSC";
import CommonTitle from "../commonTitle";
import { NoVotesSC, VotesWrapperSC } from "./index.styled";

const VoteLogs = ({ logs, isLoggedIn, title, shortName }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div style={{ flex: 1, flexBasis: "264px", maxHeight: "260px" }}>
      {title && <CommonTitle title={title} shortName={shortName} color={theme.primary} />}
      <CommonWrapperSC style={{ maxHeight: "220px" }}>
        {isLoggedIn ? (
          (logs?.length === 0 || logs === null) && <NoVotesSC>{t("no-votes")}</NoVotesSC>
        ) : (
          <NoVotesSC>{t("not-logged-in-votes")}</NoVotesSC>
        )}
        {isLoggedIn &&
          logs?.length > 0 &&
          logs.map((log) => {
            return (
              <VotesWrapperSC key={log.id}>
                <div className="icon-wrapper">
                  <img
                    src={require("../../assets/img/dota2logo.png")}
                    alt=""
                    style={{ width: "auto", height: "auto", maxWidth: "60px", maxHeight: "40px", objectFit: "contain" }}
                  />
                </div>

                <div className="info">
                  <div className="title">
                    <h2>Home name - Away name</h2>
                    <p>{log.user.name}</p>
                  </div>
                  <div className="vote-info-wrapper">
                    <h2>{log.market.name}</h2>
                    <div className="flex">
                      <p className="vote-name">&lt;{log.name}&gt;</p>
                      <p className="coef">175</p>
                    </div>
                  </div>
                </div>
              </VotesWrapperSC>
            );
          })}
      </CommonWrapperSC>
    </div>
  );
};

export default VoteLogs;
