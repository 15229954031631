import { ContainerSC } from "../commonSC";
import { LinksSC, LogosSC, WrapperSC } from "./styled";
import nomathsLogo from "../../assets/icons/nomaths-logo.svg";
import marrowlabsLogo from "../../assets/icons/marrowlabs-logo.svg";
import gridLogo from "../../assets/icons/grid-logo.svg";

const Footer = () => {
  return (
    <WrapperSC>
      <ContainerSC>
        <LinksSC>
          <div className="section">
            <p className="item">Privacy Policy</p>
            <p className="item">Terms of use</p>
            <p className="item">Faq</p>
          </div>
          <div className="section">
            <p className="item">About</p>
            <p className="item contact-us">Contact us</p>
            <p className="item email">
              <a href="mailto:info@votez.gg">info@votez.gg</a>
            </p>
          </div>
        </LinksSC>
        <LogosSC>
          <div className="section">
            <p className="title">Data Analytics by</p>
            <div className="image-wrapper">
              <a href="http://nomaths.com" target="_blank" rel="noopener noreferrer">
                <img src={nomathsLogo} alt="nomaths logo" />
              </a>
            </div>
          </div>
          <div className="section">
            <p className="title">Developed by</p>
            <div className="image-wrapper">
              <a href="https://www.marrowlabs.com" target="_blank" rel="noopener noreferrer">
                <img src={marrowlabsLogo} alt="marrowlabs logo" />
              </a>
            </div>
          </div>
          <div className="section">
            <p className="title">Powered by</p>
            <div className="image-wrapper">
              <a href="https://grid.gg" target="_blank" rel="noopener noreferrer">
                <img src={gridLogo} alt="grid logo" />
              </a>
            </div>
          </div>
        </LogosSC>
      </ContainerSC>
    </WrapperSC>
  );
};

export default Footer;
