import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const storedLang = localStorage.getItem("grid-language");

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: storedLang,
  resources: {
    en: {
      translations: require("./en/translations.json"),
    },
    es: {
      translations: require("./es/translations.json"),
    },
    zh: {
      translations: require("./zh/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es", "zh"];

export default i18n;
