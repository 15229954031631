import { ModalSC, ModalBackdropSC, ModalBoxSC } from "./styled";

const Modal = ({ children, variant, setIsOpen, ...rest }) => {
  return (
    <ModalSC
      {...rest}
      BackdropComponent={ModalBackdropSC}
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          setIsOpen(false);
        }
      }}>
      <ModalBoxSC variant={variant}>{children}</ModalBoxSC>
    </ModalSC>
  );
};

export default Modal;
