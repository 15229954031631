import create from "zustand";
import jwt from "jwt-decode";

export const useAuthStore = create((set) => ({
  token: null,
  authErrors: null,
  userDetails: null,
  isLoading: false,
  isRegisterSuccess: false,
  isLoggedIn: false,

  getUserToken: () => {
    try {
      let tokenString = localStorage.getItem("grid-token");
      if (tokenString) {
        const tokenParsed = JSON.parse(tokenString);
        if (tokenParsed) {
          set({ token: tokenParsed });
          return [tokenParsed, jwt(tokenParsed).iat];
        }
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  userLogout: () => {
    window.localStorage.removeItem("grid-token");
    set({
      userDetails: null,
      token: null,
      isLoggedIn: false,
    });
  },

  getUserDetails: async (token) => {
    const userId = jwt(token).sub;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/user/${userId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      set({ userDetails: responseJson, isLoggedIn: true });
    } catch (error) {
      console.log("get user details: ", error);
    }
  },

  updateUserDetails: async (token, avatar, buffs, twitter) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/user`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          avatar: avatar,
          buffs: buffs,
          twitter: twitter,
        }),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        set({ userError: responseJson.message });
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      set({ userDetails: responseJson });
      return true;
    } catch (error) {
      console.log("get user by id: ", error);
    }
  },

  updateUserDetailsLocally: (avatar, buffs, twitter) => {
    set((state) => ({
      userDetails: { ...state.userDetails, avatar, buffs, twitter },
    }));
  },

  userRegister: async (name, email, password) => {
    try {
      set({ isLoading: true, authErrors: null });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/auth/signup`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          name: name,
          password: password,
        }),
      });
      const responseJson = await response.json();
      if (responseJson?.data?.code === "P2002") {
        set({ authErrors: "User already exists" });
        throw Error(response.statusText);
      }
      set({ isLoading: false });
      return responseJson;
    } catch (error) {
      console.log("register error: ", error);
      set({ isLoading: false });
    }
  },

  userLogin: async (email, password) => {
    try {
      set({ isLoading: true, authErrors: null });
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/auth/signin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (!response.ok) {
        const responseJson = await response.json();
        if (responseJson.message === "USER.CREDENTIALS_INCORRECT") set({ authErrors: "Credentials incorrect" });
        if (responseJson.message === "USER.EMAIL_NOT_CONFIRMED") set({ authErrors: "Unconfirmed email" });
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      const { token, ...userData } = responseJson;
      set({ userDetails: userData.user, token: token.access_token, isLoggedIn: true, isLoading: false });
      window.localStorage.setItem("grid-token", JSON.stringify(token.access_token));
      return true;
    } catch (error) {
      console.log(error);
      set({ userDetails: null, isLoading: false, token: null, isLoggedIn: false });
    }
  },

  verifyEmail: async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/auth/email/verify/${token}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.log("verify password error: ", error);
    }
  },

  forgotPassword: async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/auth/forgot-password/${email}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const responseJson = await response.json();
      if (responseJson?.data?.message === "LOGIN.USER_NOT_FOUND") {
        set({ authErrors: "User not found" });
        return false;
      } else if (responseJson.success || responseJson?.data?.message === "RESET_PASSWORD.EMAIL_SENT_RECENTLY") {
        return true;
      }
    } catch (error) {
      console.log("forgot password error: ", error);
    }
  },

  resetPassword: async (password, token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/auth/email/reset-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newPassword: password,
          newPasswordToken: token,
        }),
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.log("reset password error: ", error);
    }
  },

  clearAuthErrors: () => {
    set({ authErrors: null });
  },
}));
