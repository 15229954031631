import React from "react";
import { useTranslation } from "react-i18next";
import { InnerTitleSC, WrapperSC } from "./commonTitle.styled";

const CommonTitle = ({ title, color, shortName }) => {
  const { t } = useTranslation();

  return (
    <WrapperSC color={color}>
      <InnerTitleSC color={color}>
        <p>{shortName}</p>
      </InnerTitleSC>
      <h1>{t(title)}</h1>
    </WrapperSC>
  );
};

export default CommonTitle;
