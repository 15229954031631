import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useLanguageStore } from "./stores/language";
import { useAuthStore } from "./stores/auth";

import StyleProvider from "./styles/main";
import { useDarkMode } from "./styles/useDarkMode";

import AppNav from "./components/AppNav";

import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import UserProfile from "./pages/UserProfile";
import Game from "./pages/Game";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";
import ModalLogin from "./components/Auth/ModalLogin";
import ModalRegister from "./components/Auth/ModalRegister";
import ModalForgotPassword from "./components/Auth/ModalForgotPassword";
import Tournament from "./pages/Tournament";
import HowToPlay from "./pages/HowToPlay";
import Footer from "./components/Footer";
import { MainSC } from "./components/commonSC";

function App() {
  const [theme, toggleTheme, checkedTheme] = useDarkMode();
  const { getLanguage } = useLanguageStore();
  const { getUserToken, userLogout, getUserDetails } = useAuthStore();
  const [checkedUser, setCheckedUser] = useState(false);

  useEffect(() => {
    getLanguage();
  }, [getLanguage]);

  useEffect(() => {
    let logoutInterval;
    const token = getUserToken();
    if (token) {
      (async () => {
        // token valid for 23.5 hours (almost 24 hours)
        let tokenValidityInterval = 1000 * 60 * 60 * 23.5;
        let expirationTime = token[1] * 1000 + tokenValidityInterval - Date.now();
        if (expirationTime > 0) {
          // if token still valid get userdata and do logout in calculated expiration time
          await getUserDetails(token[0]);
          logoutInterval = setTimeout(() => {
            userLogout();
          }, expirationTime);
        } else {
          // if token in local storage but outdated
          userLogout();
        }
        setCheckedUser(true);
      })();
    } else {
      setCheckedUser(true);
    }

    return () => {
      clearInterval(logoutInterval);
    };
  }, [getUserToken, getUserDetails, userLogout]);

  if (!checkedTheme || !checkedUser) return null;

  return (
    <StyleProvider theme={theme}>
      <AppNav theme={theme} toggleTheme={toggleTheme} />
      <MainSC>
        <Routes>
          <Route path="/" element={<Home theme={theme} />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/schedule" element={<Tournament />} />
          <Route path="/user/:userId" element={<UserProfile />} />
          <Route path="/game/:gameId" element={<Game />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/how-to-play" element={<HowToPlay />} />
        </Routes>
      </MainSC>
      <Footer />

      <ModalLogin />
      <ModalRegister />
      <ModalForgotPassword />
    </StyleProvider>
  );
}

export default App;
