import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { CommonWrapperSC } from "../../components/commonSC";
import CommonTitle from "../../components/commonTitle";
import { UpcomingWrapperSC } from "./UpcommingMatches.styled";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const UpcommingMatches = ({ series }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [filteredSeries, setFilteredSeries] = useState([]);

  const handleCardClick = (id) => {
    navigate(`/game/${id}`);
  };

  useEffect(() => {
    if (series?.length > 0) {
      setFilteredSeries(series.filter((serie) => serie.isLive === false));
    }
  }, [series]);

  return (
    <div style={{ flex: 1 }}>
      <CommonTitle title="upcoming-matches" shortName="UM" color={theme.neutral4} />
      <CommonWrapperSC style={{ maxHeight: "260px" }}>
        {filteredSeries?.length === 0 && <p className="no-games">There are currently no live games.</p>}
        {filteredSeries?.length > 0 &&
          filteredSeries.map((serie) => {
            return (
              <UpcomingWrapperSC key={serie.id} onClick={() => handleCardClick(serie.id)}>
                <div className="icon-wrapper">
                  <img
                    src={require("../../assets/img/dota2logo.png")}
                    alt=""
                    style={{ width: "auto", height: "auto", maxWidth: "60px", maxHeight: "60px", objectFit: "contain" }}
                  />
                </div>
                <div className="info">
                  <div className="title">
                    <h2>{serie.tournament.shortName}</h2>
                    <p>
                      Active votes options: <b style={{ color: theme.primary }}>{serie.markets.length}</b>
                    </p>
                  </div>
                  <div className="team-info-wrapper">
                    <div className="team-info">
                      <img
                        src={require("../../assets/img/dota-bg.png")}
                        alt=""
                        style={{ width: "16px", height: "16px" }}
                      />
                      <p className="team-name">{serie.matchTeams[0].team.name}</p>
                      <p className="team-score">Starts in</p>
                    </div>
                    <div className="team-info">
                      <img
                        src={require("../../assets/img/dota-bg.png")}
                        alt=""
                        style={{ width: "16px", height: "16px" }}
                      />
                      <p className="team-name">{serie.matchTeams[1].team.name}</p>
                      <p className="team-score">
                        {format(new Date(serie.scheduledStartTime), "H")}h{" "}
                        {format(new Date(serie.scheduledStartTime), "mm")}m
                      </p>
                    </div>
                  </div>
                </div>
              </UpcomingWrapperSC>
            );
          })}
      </CommonWrapperSC>
    </div>
  );
};

export default UpcommingMatches;
