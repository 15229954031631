import { useNavigate } from "react-router-dom";
import { useLeaderboardStore } from "../../stores/leaderboard";
import { Table, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { NoIconSC } from "../../components/commonSC";
import { TableBodySC, TableSectionSC } from "./Leaderboard.styled";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CommonTitle from "../../components/commonTitle";
import { useTheme } from "styled-components";

const Leaderboard = () => {
  const theme = useTheme();
  const matches1280Up = useMediaQuery("(min-width:1280px)");
  const matches768Down = useMediaQuery("(max-width:768px)");
  const { totalUsersCount, usersPerPage, top5Users, getTop5Users, getBottom5Users, bottom5Users } =
    useLeaderboardStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getTop5Users(usersPerPage, 0);
    getBottom5Users();
  }, [getTop5Users, usersPerPage, getBottom5Users]);

  const handleRowClick = (id) => {
    navigate(`/user/${id}`);
  };

  return (
    <>
      {top5Users?.length > 0 && (
        <>
          <CommonTitle title="hall-of-fame" shortName="HF" color={theme.primary} />
          <TableSectionSC>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="first">{t("user-leaderboard")}</TableCell>
                    {!matches768Down && <TableCell>{t("level")}</TableCell>}
                    <TableCell>{t("win-ratio")}</TableCell>
                    {matches1280Up && (
                      <>
                        <TableCell>{t("total-wins")}</TableCell>
                        <TableCell>{t("biggest-streak")}</TableCell>
                        <TableCell>{t("current-streak")}</TableCell>
                      </>
                    )}
                    <TableCell>{t("currency")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBodySC>
                  {top5Users?.map((item, index) => {
                    return (
                      <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
                        <TableCell className="first">
                          <div className="rank">{item.rank}</div>
                          <div className="image-name">
                            {item.avatar ? (
                              <img
                                className="image"
                                src={require(`../../assets/icons/hero-avatars/${item.avatar}`)}
                                alt="Avatar"
                              />
                            ) : (
                              <NoIconSC size="28" style={{ marginRight: "16px" }}>
                                <div className="no-icon" />
                              </NoIconSC>
                            )}
                            <span className="name">{item.name}</span>
                          </div>
                        </TableCell>
                        {!matches768Down && (
                          <TableCell className="level">
                            {t("level")} {item.level}
                          </TableCell>
                        )}
                        <TableCell>{item.voteStatistics.winPercentage}%</TableCell>
                        {matches1280Up && (
                          <>
                            <TableCell>{item.voteStatistics.numberOfWins}</TableCell>
                            <TableCell>{item.voteStatistics.biggestWinStreak}</TableCell>
                            <TableCell>{item.voteStatistics.winStreak}</TableCell>
                          </>
                        )}
                        <TableCell className="buffs">{item.buffs}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBodySC>
              </Table>
            </TableContainer>
          </TableSectionSC>
        </>
      )}
      {totalUsersCount >= usersPerPage + 5 && (
        <>
          <CommonTitle title="hall-of-shame" shortName="HS" color={theme.primary} />
          <TableSectionSC>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="first">{t("user-leaderboard")}</TableCell>
                    {!matches768Down && <TableCell>{t("level")}</TableCell>}
                    <TableCell>{t("win-ratio")}</TableCell>
                    {matches1280Up && (
                      <>
                        <TableCell>{t("total-wins")}</TableCell>
                        <TableCell>{t("biggest-streak")}</TableCell>
                        <TableCell>{t("current-streak")}</TableCell>
                      </>
                    )}
                    <TableCell>{t("currency")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBodySC>
                  {bottom5Users?.map((item, index) => {
                    return (
                      <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
                        <TableCell className="first">
                          <div className="rank">{item.rank}</div>
                          <div className="image-name">
                            {item.avatar ? (
                              <img
                                className="image"
                                src={require(`../../assets/icons/hero-avatars/${item.avatar}`)}
                                alt="Avatar"
                              />
                            ) : (
                              <NoIconSC size="28" style={{ marginRight: "16px" }}>
                                <div className="no-icon" />
                              </NoIconSC>
                            )}
                            <span className="name">{item.name}</span>
                          </div>
                        </TableCell>
                        {!matches768Down && (
                          <TableCell className="level">
                            {t("level")} {item.level}
                          </TableCell>
                        )}
                        <TableCell>{item.voteStatistics.winPercentage}%</TableCell>
                        {matches1280Up && (
                          <>
                            <TableCell>{item.voteStatistics.numberOfWins}</TableCell>
                            <TableCell>{item.voteStatistics.biggestWinStreak}</TableCell>
                            <TableCell>{item.voteStatistics.winStreak}</TableCell>
                          </>
                        )}
                        <TableCell className="buffs">{item.buffs}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBodySC>
              </Table>
            </TableContainer>
          </TableSectionSC>
        </>
      )}
    </>
  );
};

export default Leaderboard;
