import styled from "styled-components";
import { Accordion, TextField } from "@mui/material";
import levelOutlineSVG from "../../assets/img/level-outline.svg";
import DotaIconBG from "../../assets/img/dota-colored.svg";
import AccordionSVG from "../../assets/img/accordion-icon.svg";
import twitterIconSVG from "../../assets/icons/twitter-icon.svg";
import winIconSVG from "../../assets/icons/win-vote.svg";
import loseIconSVG from "../../assets/icons/lose-vote.svg";
import progressIconSVG from "../../assets/icons/progress-vote.svg";
import followIconSVG from "../../assets/icons/twitter-follow.svg";
import editIconSVG from "../../assets/icons/edit-icon.svg";
import shareIconSVG from "../../assets/icons/share-icon.svg";
import accountIcon from "../../assets/icons/account.svg";

export const ProfileSectionSC = styled.section`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 88px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 40px;
    }

    .header-content {
      display: flex;
      flex-direction: column;

      @media (max-width: 1023px) {
        justify-content: flex-start;
      }
    }

    .header-inner {
      display: flex;
    }

    .rank {
      min-width: 88px;
      height: 88px;
      background-color: ${({ theme }) => theme.primary};
      font-size: 36px;
      font-weight: 700;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name-and-stats {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 24px;

      h1 {
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 45px;
        color: ${({ theme }) => theme.neutral4};
      }
    }

    .stats-and-twitter {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        margin-top: 16px;
      }

      @media (max-width: 600px) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .stats {
      display: flex;

      h2 {
        font-size: 20px;
        font-weight: 500;
        font-family: ${({ theme }) => theme.fontSub};
        line-height: 24px;
      }

      h2:first-of-type {
        color: ${({ theme }) => theme.neutral2};
        margin-right: 16px;
      }

      h2:last-of-type {
        color: ${({ theme }) => theme.primary};
      }
    }

    .twitter {
      display: flex;
      align-items: center;
      margin-left: 28px;

      @media (max-width: 600px) {
        margin-left: 0;
        margin-top: 8px;
      }

      p {
        color: ${({ theme }) => theme.neutral2};
      }
    }

    .twitter-add {
      cursor: pointer;
    }

    p.add-twitter {
      text-decoration: underline;
    }

    .twitter-icon {
      width: 18px;
      height: 16px;
      -webkit-mask: url(${twitterIconSVG}) no-repeat center;
      mask: url(${twitterIconSVG}) no-repeat center;
      background-color: ${({ theme }) => theme.primary};
      margin-right: 8px;
    }

    .edit-icon {
      width: 30px;
      height: 30px;
      transform: scale(0.7);
      -webkit-mask: url(${editIconSVG}) no-repeat center;
      mask: url(${editIconSVG}) no-repeat center;
      background-color: ${({ theme }) => theme.primary};
      margin-left: 12px;
      cursor: pointer;

      @media (max-width: 600px) {
        margin-top: 4px;
      }
    }

    .button-back {
      height: 56px;
      padding: 0 24px;
      background-color: ${({ theme }) => (theme.body === "#FFFFFF" ? "transparent" : theme.neutral1)};
      color: ${({ theme }) => theme.primary};
      border: ${({ theme }) => `1px solid ${theme.primary}`};
      font-size: 20px;
      font-weight: 400;
      border-radius: 8px;
      cursor: pointer;
      text-transform: uppercase;

      :hover {
        background-color: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.neutral4};
        border: ${({ theme }) => `1px solid ${theme.primary}`};
      }

      @media (max-width: 1023px) {
        margin-bottom: 20px;
      }
    }
  }
`;

export const ShareWrapperSC = styled.div`
  .react-share__ShareButton {
    width: 28px;
    height: 28px;
  }

  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`;

export const ButtonShareSC = styled.div`
  width: 28px;
  height: 28px;
  -webkit-mask: url(${shareIconSVG}) no-repeat center;
  mask: url(${shareIconSVG}) no-repeat center;
  background-color: ${({ theme }) => theme.primary};
`;

export const ProfileStatisticsSC = styled.div`
  display: flex;
  margin-top: 48px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 30px;
  }

  .row-1 {
    display: flex;
    flex-direction: column;
    width: 112px;

    @media (max-width: 767px) {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      border: ${({ theme }) => `1px solid ${theme.neutral3}`};
      color: ${({ theme }) => theme.neutral3};
      width: fit-content;
      height: 28px;
      padding: 0 16px;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      font-family: ${({ theme }) => theme.fontSub};
      white-space: nowrap;

      @media (max-width: 767px) {
        margin-right: 8px;
      }
    }
  }

  .row-aside {
    display: flex;
    width: 100%;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    flex-wrap: wrap;
    gap: 66px;
  }

  .row-2 {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 312px;

    @media (max-width: 767px) {
      margin-right: -38px;
    }

    .avatar-container {
      border: 1px solid #1e4951;
      border-radius: 20px;
      height: 312px;
      display: flex;
      justify-content: center;
      align-items: center;
      .add-avatar {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: underline;
        color: ${({ theme }) => theme.neutral2};
        :hover {
          cursor: pointer;
        }
      }

      .no-avatar {
        width: 150px;
        height: 150px;
        -webkit-mask: url(${accountIcon}) no-repeat center;
        mask: url(${accountIcon}) no-repeat center;
        background-color: ${({ theme }) => theme.neutral3};
        object-fit: cover;
      }
    }

    .image-main {
      width: 312px;
      height: 312px;
      object-fit: cover;
      border-radius: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      :hover {
        filter: blur(2px);
        transform: scale(1.02, 1.02);
      }
      @media (max-width: 600px) {
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
      }
    }

    .social {
      display: flex;
      margin-top: 24px;

      @media (max-width: 600px) {
        margin-bottom: 30px;
      }

      .row {
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 9px;
          font-weight: 500;
          color: ${({ theme }) => theme.neutral3};
          text-transform: uppercase;
          font-family: ${({ theme }) => theme.fontSub};
          font-style: italic;
        }

        p {
          font-size: 14px;
          font-weight: 700;
          color: ${({ theme }) => theme.neutral2};
        }

        &:not(:last-of-type) {
          margin-right: 32px;
        }
      }

      button.follow {
        display: flex;
        align-items: center;
        background-color: transparent;
        height: 28px;
        padding: 0 20px;
        border: ${({ theme }) => `1px solid ${theme.primary}`};
        border-radius: 8px;
        cursor: pointer;

        .icon {
          width: 18px;
          height: 18px;
          -webkit-mask: url(${followIconSVG}) no-repeat center;
          mask: url(${followIconSVG}) no-repeat center;
          background-color: ${({ theme }) => theme.primary};
          margin-right: 12px;
        }

        .text {
          font-size: 12px;
          font-weight: 500;
          color: ${({ theme }) => theme.primary};
          text-transform: uppercase;
          font-family: ${({ theme }) => theme.fontMain};
        }
      }
    }
  }

  .row-3 {
    flex: 1;
    display: flex;
    flex-direction: column;

    .badge-wrapper {
      margin-bottom: 36px;
    }

    .text-head {
      font-size: 9px;
      font-weight: 500;
      color: ${({ theme }) => theme.neutral3};
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.fontSub};
      font-style: italic;
    }

    .level {
      font-size: 13px;
      font-weight: 800;
      color: ${({ theme }) => theme.primary};
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .level-icon-wrapper {
      position: relative;
      width: 70px;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;

      .icon {
        position: absolute;
        top: 0;
        width: 70px;
        height: 84px;
        -webkit-mask: url(${levelOutlineSVG}) no-repeat center;
        mask: url(${levelOutlineSVG}) no-repeat center;
        background-color: ${({ theme }) => theme.neutral2};
      }

      p {
        font-weight: 700;
        font-size: 36px;
        color: ${({ theme }) => theme.neutral2};
      }
    }

    .until-next-level {
      font-weight: 500;
      font-size: 14px;
      color: ${({ theme }) => theme.neutral2};
      text-transform: uppercase;

      @media (max-width: 600px) {
        margin-bottom: 30px;
      }
    }

    .dota-row {
      display: flex;
      margin-top: 6px;
      margin-bottom: 18px;

      .icon {
        width: 24px;
        height: 20px;
        -webkit-mask: url(${DotaIconBG}) no-repeat center;
        mask: url(${DotaIconBG}) no-repeat center;
        background-color: ${({ theme }) => theme.primary};
      }

      .dota-text {
        margin-left: 12px;
        font-weight: 500;
        font-size: 14px;
        color: ${({ theme }) => theme.primary};
        text-transform: uppercase;
      }
    }

    .ratio-row {
      display: flex;
      align-items: center;
      line-height: 24px;

      .ratio-value {
        font-weight: 500;
        font-size: 12px;
        color: ${({ theme }) => theme.primary};
        min-width: 36px;
      }

      span {
        font-weight: 500;
        font-size: 12px;
        color: ${({ theme }) => theme.neutral3};
      }

      .ratio-description {
        font-weight: 500;
        font-size: 12px;
        color: ${({ theme }) => theme.neutral3};
        margin-left: 4px;
        font-family: ${({ theme }) => theme.fontSub};
      }
    }
  }

  .row-4 {
    border: 1px solid ${({ theme }) => theme.neutral1};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 28px 24px;
    width: 100%;
    max-width: 400px;

    .win-icon {
      width: 14px;
      height: 10px;
      -webkit-mask: url(${winIconSVG}) no-repeat center;
      mask: url(${winIconSVG}) no-repeat center;
      background-color: ${({ theme }) => theme.primary};
      margin-right: 8px;
    }

    .lose-icon {
      width: 14px;
      height: 10px;
      -webkit-mask: url(${loseIconSVG}) no-repeat center;
      mask: url(${loseIconSVG}) no-repeat center;
      background-color: ${({ theme }) => theme.danger};
      margin-right: 8px;
    }

    .progress-icon {
      width: 14px;
      height: 10px;
      -webkit-mask: url(${progressIconSVG}) no-repeat center;
      mask: url(${progressIconSVG}) no-repeat center;
      background-color: ${({ theme }) => theme.neutral2};
      margin-right: 8px;
    }

    .title-container {
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: 1px solid ${({ theme }) => theme.neutral1};
      h2 {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: ${({ theme }) => theme.neutral4};
      }
    }

    .flex {
      display: flex;
      margin-bottom: 4px;
      .title {
        display: flex;
        flex: 1;
        margin-right: 4px;
      }
    }
    .vote-logs {
      height: 100%;
      max-height: 280px;
      overflow-y: auto;
    }
    .date {
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      margin-right: 16px;
      word-break: keep-all;
      color: ${({ theme }) => theme.neutral3};
    }

    .no-votes {
      align-self: center;
      color: ${({ theme }) => theme.neutral1};
    }

    h2 {
      color: #3bbdc0;
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
    }

    .market {
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      cursor: pointer;
      color: ${({ theme }) => theme.neutral4};
    }
  }
`;

export const AccordionWrapperSC = styled.div`
  margin-top: 120px;
  margin-bottom: 60px;

  @media (max-width: 767px) {
    margin-top: 60px;
  }
`;

export const AccordionArrowSC = styled.div`
  width: 24px;
  height: 14px;
  -webkit-mask: url(${AccordionSVG}) no-repeat center;
  mask: url(${AccordionSVG}) no-repeat center;
  background-color: ${({ theme }) => theme.neutral1};
`;

export const AccordionMuiSC = styled(Accordion)`
  &.MuiAccordion-root {
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.neutral3};
    border-radius: 0;

    &::before {
      content: "";
      height: 0;
    }
  }

  .MuiAccordionSummary-root {
    border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
    padding: 0;
    font-weight: 700;
    font-size: 20px;
    color: ${({ theme }) => theme.neutral2};
  }

  .MuiAccordionSummary-content {
    margin: 0;
    height: 64px;

    h3 {
      display: flex;
      align-items: center;
    }

    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    margin: 16px 0;
    padding: 0;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const BadgeItemSC = styled.div`
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const AccordionSummaryTextSC = styled.h3`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.neutral3};
`;

export const AccordionDetailsTextSC = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral2};
  font-family: ${({ theme }) => theme.fontSub};
  text-align: center;
  width: 100%;
`;

export const ContentSC = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 30px 30px 60px 30px;
  height: auto;
  border-radius: 20px;
  background: ${({ theme }) => theme.body};
  display: flex;
  flex-direction: column;
  position: relative;
  top: -30px;
`;

export const InputWrapperSC = styled.div`
  margin-bottom: 8px;
`;

export const TitleSC = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 43px;
  margin-top: 40px;
  text-align: center;
`;

export const TextErrorSC = styled.p`
  font-family: ${({ theme }) => theme.fontSub};
  margin: 0px 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: ${({ theme }) => theme.danger};
  @media (max-width: 600px) {
    font-size: 9px;
  }
`;

export const TextFieldSC = styled(TextField)`
  // margin-bottom: 20px !important;
  width: 100%;
  & .MuiInputLabel-root {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.neutral1};
  }
  & label.Mui-focused {
    color: ${({ theme }) => theme.primary};
  }
  & .MuiInput-input {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.neutral3};
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #d3d3d3;
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 2px solid ${({ theme }) => theme.primary};
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid ${({ theme }) => theme.primary};
  }
`;

export const SubmitButtonSC = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  margin-top: 32px;

  :disabled {
    cursor: not-allowed;
  }
`;

export const AvatarImageSC = styled.img`
  width: 120px;
  height: 80px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :hover {
    border: 4px solid ${({ theme }) => theme.primary};
  }
  border: ${(props) => (props.avatarUrl === props.alt ? `4px solid #63CCC0` : "none")};
`;
