import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const ContentSC = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 30px 30px 60px 30px;
  height: auto;
  border-radius: 20px;
  background: ${({ theme }) => theme.body};
  display: flex;
  flex-direction: column;
  position: relative;
  top: -30px;
`;

export const BottomWrapperSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
`;

export const BottomInnerWrapperSC = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const InputWrapperSC = styled.div`
  margin-bottom: 8px;
`;

export const ImgSC = styled.img`
  max-width: 360px;
  border-radius: 20px;
  // @media (max-width: 600px) {
  //   max-width: 600px;
  // }
`;

export const TitleSC = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 43px;
  margin-top: 15px;
`;

export const TextSecondarySC = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: ${({ theme }) => theme.primary};
  margin: 0;
`;

export const AuthTypeTextSC = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.primary};
  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
  margin: 14px 0 4px 0;
`;

export const TextErrorSC = styled.p`
  font-family: ${({ theme }) => theme.fontSub};
  margin: 0px 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: ${({ theme }) => theme.danger};
  @media (max-width: 600px) {
    font-size: 9px;
  }
`;

export const ButtonSC = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 32px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: scale(1.02);
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextFieldSC = styled(TextField)`
  // margin-bottom: 20px !important;
  width: 100%;
  & .MuiInputLabel-root {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.neutral1};
  }
  & label.Mui-focused {
    color: ${({ theme }) => theme.primary};
  }
  & .MuiInput-input {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.neutral3};
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #d3d3d3;
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 2px solid ${({ theme }) => theme.primary};
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid ${({ theme }) => theme.primary};
  }
`;

export const LabelSC = styled.label`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;

  span {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: ${({ theme }) => theme.neutral2};
  }
  .highlight {
    color: ${({ theme }) => theme.primary};
    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
`;
