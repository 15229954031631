import { useTranslation } from "react-i18next";
import { ContainerSC } from "../../components/commonSC";
import { TitleSC, SubtitleSC, TextSC, TextItalicSC } from "./styled";

const HowToPlay = () => {
  const { t } = useTranslation();

  return (
    <ContainerSC>
      <TitleSC>{t("how-to-play")}</TitleSC>
      <TextItalicSC>{t("how-to-play-text-0")}</TextItalicSC>
      <TextSC>{t("how-to-play-text-1")}</TextSC>
      <SubtitleSC>{t("points")}</SubtitleSC>
      <TextSC>{t("how-to-play-text-2")}</TextSC>
      <TextSC>{t("how-to-play-text-3")}</TextSC>
      <SubtitleSC>{t("boosters")}</SubtitleSC>
      <TextSC>{t("how-to-play-text-4")}</TextSC>
      <TextSC>{t("how-to-play-text-5")}</TextSC>
      <TextSC>{t("how-to-play-text-6")}</TextSC>
    </ContainerSC>
  );
};

export default HowToPlay;
