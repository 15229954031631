import React, { useEffect, useMemo, useState } from "react";
import {
  CardSC,
  ResultSC,
  TeamLeftSC,
  TeamRightSC,
  LogosAndResultSC,
  TournamentInfoSC,
  TeamNamesSC,
  SmallbarListSC,
  SmallbarItemSC,
  BetWrapperSC,
  ToggleBetButtonSC,
  BuffsWrapperSC,
  ButtonConfirmSC,
  EmptyMarketsContainerSC,
  PickBanWrapper,
  ButtonAddBoosterSC,
  BoosterButtonSC,
  TotalItemSC,
  MarketSC,
  MarketButtonSC,
  DescriptionTextSC,
  MarketsSC,
} from "./VoteCard.styled";
import { toast } from "react-toastify";
import { useAuthStore } from "../../stores/auth";
import { useModalStore } from "../../stores/modal";
import { useTranslation } from "react-i18next";

const VoteCard = ({ serieId, setUserVoted }) => {
  const { isLoggedIn, userDetails, token } = useAuthStore();
  const { setModal } = useModalStore();
  const { t } = useTranslation();
  const [serie, setSerie] = useState(null);
  const [smallbarItem, setSmallbarItem] = useState("Pick & Ban");
  const [betButton, setBetButton] = useState({
    id: -1,
    coefficient: 0,
    name: "",
    status: null,
    suspended: false,
    hidden: false,
  });
  const [boosterButton, setBoosterButton] = useState([]);
  const [pickedBooster, setPickedBooster] = useState({ id: -1, coefficient: 0, name: "" });
  const [potentionalWin, setPotentionalWin] = useState(0.0);
  const [potentionalLose, setPotentionalLose] = useState(0.0);
  const [markets, setMarkets] = useState(null);
  const [boosters, setBoosters] = useState([]);
  const [loadingBoosters, setLoadingBoosters] = useState(true);
  const [readMoreExpanded, setReadMoreExpanded] = useState(false);

  const descriptionLength = 80;

  const getMatch = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/match/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      let data = await response.json();
      setSerie(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBoosters = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/booster/${serieId}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();

      if (response.ok) {
        setBoosters(data);
      }
      setLoadingBoosters(false);
    } catch (error) {
      console.log(error);
      if (!isLoggedIn) {
        setBoosters([]);
      }
      setLoadingBoosters(false);
    }
  };

  const getMarkets = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/market?matchId=${serieId}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
      let data = await response.json();

      if (response.ok) {
        setMarkets(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddBooster = async () => {
    if (!isLoggedIn) {
      setModal("login");
      return;
    }
    try {
      if (boosterButton.length < 2) {
        toast.error(t("toast-error-select-pick-ban"));
        return;
      }

      const boosterIds = boosterButton.map((booster) => {
        return { boosterId: booster.id, userId: userDetails.id, matchId: serieId };
      });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/booster/user-booster/create`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: boosterIds,
        }),
      });

      if (response.ok) {
        toast.success(t("toast-success-add-booster"));
        getBoosters();
      } else {
        toast.error(t("toast-error-already-added"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVote = async () => {
    if (!isLoggedIn) {
      setModal("login");
      return;
    }
    if (betButton.status !== 0 && betButton.hidden && betButton.suspended) {
      toast.error(t("market-expired"));
      return;
    }

    try {
      if (betButton.id === -1) {
        toast.error(t("toast-error-bet"));
        return;
      }
      let response = undefined;
      // with booster
      if (pickedBooster.id !== -1) {
        response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vote/create`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: betButton.name,
            userId: userDetails.id,
            marketOptionId: betButton.id,
            balance: 100,
            userBoosterId: pickedBooster.id ? pickedBooster.id : null,
          }),
        });
        const responseData = await response.json();
        if (response.ok) {
          getBoosters();
          setPickedBooster({ id: -1, coefficient: 0, name: "" });
          setBetButton({
            id: -1,
            coefficient: 0,
            name: "",
            status: null,
            suspended: false,
            hidden: false,
          });
          setPotentionalWin(0.0);
          setPotentionalLose(0.0);

          if (setUserVoted !== undefined) setUserVoted(true);
          toast.success(t("toast-success-vote"));
        } else if (!responseData.success) {
          if (responseData.data.message === "USER.ALREADY_VOTED") toast.error(t("already-voted"));
          else if (responseData.data.message === "MARKET.IS_HIDDEN") {
            toast.error(t("market-hidden"));
            getMarkets();
          } else if (responseData.data.message === "MARKET.IS_SUSPENDED") {
            toast.error(t("market-suspended"));
            getMarkets();
          }
          setBetButton({
            id: -1,
            coefficient: 0,
            name: "",
            status: null,
            suspended: false,
            hidden: false,
          });
          setPotentionalWin(0.0);
          setPotentionalLose(0.0);
          setPickedBooster({ id: -1, coefficient: 0, name: "" });
        }
      }
      // without booster
      else {
        response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vote/create`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: betButton.name,
            userId: userDetails.id,
            marketOptionId: betButton.id,
            balance: 100,
          }),
        });
        const responseData = await response.json();
        if (response.ok) {
          toast.success(t("toast-success-vote"));
          getBoosters();
          setBetButton({
            id: -1,
            coefficient: 0,
            name: "",
            status: null,
            suspended: false,
            hidden: false,
          });
          setPotentionalWin(0.0);
          if (setUserVoted !== undefined) setUserVoted(true);
        } else if (!responseData.success) {
          if (responseData.data.message === "USER.ALREADY_VOTED") toast.error(t("already-voted"));
          else if (responseData.data.message === "MARKET.IS_HIDDEN") {
            toast.error(t("market-hidden"));
            getMarkets();
          } else if (responseData.data.message === "MARKET.IS_SUSPENDED") {
            toast.error(t("market-suspended"));
            getMarkets();
          }
          setBetButton({
            id: -1,
            coefficient: 0,
            name: "",
            status: null,
            suspended: false,
            hidden: false,
          });
          setPotentionalWin(0.0);
        }
      }
    } catch (error) {
      console.log("Vote error: ", error);
      setBetButton({
        id: -1,
        coefficient: 0,
        name: "",
        status: null,
        suspended: false,
        hidden: false,
      });
      setPickedBooster({ id: -1, coefficient: 0, name: "" });
    }
  };

  const displayMarkets = useMemo(() => {
    let computedMarkets = markets;
    if (!computedMarkets) computedMarkets = [];
    if (smallbarItem === "Towers") {
      return computedMarkets.filter(
        (market) => (market.eventType === smallbarItem || market.eventType === "TowersCreep") && !market.hidden,
      );
    } else return computedMarkets.filter((market) => market.eventType === smallbarItem && !market.hidden);
  }, [markets, smallbarItem]);

  const handleReadMore = () => {
    readMoreExpanded ? setReadMoreExpanded(false) : setReadMoreExpanded(true);
  };

  useEffect(() => {
    let matchInterval;
    if (serieId) {
      if (!serie) {
        getMatch(serieId);
      } else {
        matchInterval = setInterval(() => {
          getMatch(serieId);
          getMarkets();
          getBoosters();
        }, 30000);
      }
    }

    return () => {
      clearInterval(matchInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serieId, serie]);

  useEffect(() => {
    if (serieId) {
      getMarkets();
    }
    //eslint-disable-next-line
  }, [serieId]);

  useEffect(() => {
    if (serieId) {
      getBoosters();
    }
    //eslint-disable-next-line
  }, [isLoggedIn, serieId]);

  useEffect(() => {
    if (betButton?.coefficient) {
      var resultWin = 1;
      var resultLose = -100;
      if (pickedBooster.coefficient !== 0) {
        resultWin = resultWin * parseFloat(pickedBooster?.coefficient);
        if (pickedBooster.name === "Ban") {
          resultLose = resultLose * parseFloat(pickedBooster?.coefficient) * 2;
        }
      }
      resultWin = resultWin * parseFloat(betButton?.coefficient);

      setPotentionalWin(Math.ceil(resultWin.toFixed(2)));
      setPotentionalLose(Math.ceil(resultLose.toFixed(2)));
    }
  }, [betButton, pickedBooster]);

  if (!serie) return <div style={{ marginTop: "40px" }} />;

  return (
    <CardSC>
      <>
        <TournamentInfoSC>
          {serie.tournament?.name} - {serie.format}
        </TournamentInfoSC>
        <LogosAndResultSC>
          <TeamLeftSC teamColor={serie.matchTeams[0]?.team?.primaryColor}>
            <img src={serie.matchTeams[0]?.team?.logoUrl} alt="" />
          </TeamLeftSC>
          <ResultSC>
            <h3 className="team-left">-</h3>
            <span>:</span>
            <h3 className="team-right">-</h3>
            {serie.scheduledStartTimeEpochSeconds * 1000 <= Date.now() &&
              serie.scheduledStartTimeEpochSeconds * 1000 >= Date.now() - 8 * 60 * 60 * 1000 && (
                <div className="live-status">
                  <div className="play" />
                  <p>{t("live")}</p>
                </div>
              )}
          </ResultSC>
          <TeamRightSC teamColor={serie.matchTeams[1]?.team?.primaryColor}>
            <img src={serie.matchTeams[1]?.team?.logoUrl} alt="" />
          </TeamRightSC>
        </LogosAndResultSC>
        <TeamNamesSC>
          <h3 className="team-left">{serie.matchTeams[0]?.team?.name}</h3>
          <h3 className="team-right">{serie.matchTeams[1]?.team?.name}</h3>
        </TeamNamesSC>
      </>

      <SmallbarListSC>
        <SmallbarItemSC
          active={smallbarItem === "Pick & Ban"}
          onClick={() => {
            setReadMoreExpanded(false);
            setSmallbarItem("Pick & Ban");
          }}>
          <span className="smallbar-icon ban" />
          <p>
            {t("pick")}&{t("ban")}
          </p>
        </SmallbarItemSC>
        <SmallbarItemSC
          active={smallbarItem === "Ancient"}
          onClick={() => {
            setReadMoreExpanded(false);
            setSmallbarItem("Ancient");
          }}>
          <span className="smallbar-icon win" />
          <p>{t("ancient")}</p>
        </SmallbarItemSC>
        <SmallbarItemSC
          active={smallbarItem === "Players"}
          onClick={() => {
            setReadMoreExpanded(false);
            setSmallbarItem("Players");
          }}>
          <span className="smallbar-icon joy" />
          <p>{t("players")}</p>
        </SmallbarItemSC>
        <SmallbarItemSC
          active={smallbarItem === "Towers"}
          onClick={() => {
            setReadMoreExpanded(false);
            setSmallbarItem("Towers");
          }}>
          <span className="smallbar-icon target" />
          <p>{t("towers")}</p>
        </SmallbarItemSC>
      </SmallbarListSC>

      {smallbarItem === "Pick & Ban" && serie?.matchBoosters.length > 0 && (
        <PickBanWrapper>
          <DescriptionTextSC>
            <p className="desc-text">
              {readMoreExpanded
                ? t("votecard-description-pickban")
                : t("votecard-description-pickban").length <= descriptionLength
                ? t("votecard-description-pickban")
                : `${t("votecard-description-pickban").slice(0, descriptionLength)}...`}
            </p>
            {t("votecard-description-pickban").length > descriptionLength && (
              <button className="read-more" onClick={handleReadMore}>
                {readMoreExpanded ? t("read-less") : t("read-more")}
              </button>
            )}
          </DescriptionTextSC>
          <div className="header">
            <p>{t("hero")}</p>
            <div className="right-part">
              <div className="inner">
                <span className="pick-icon" />
                <p>{t("pick")}</p>
              </div>
              <div className="inner">
                <span className="ban-icon" />
                <p>{t("ban")}</p>
              </div>
            </div>
          </div>
          <div className="pick-ban-buttons">
            {serie?.matchBoosters?.map((hero) => {
              return (
                <div className="pick-ban" key={`${hero.hero.id}-${hero.hero.name}`}>
                  <div className="hero">
                    <img className="hero-image" src={hero.hero?.avatarUrl} alt="" />
                    <p className="hero-name">{hero.hero.localized_name}</p>
                  </div>
                  <div
                    className="boosters"
                    style={{
                      flexDirection: hero?.boosters[0]?.name === "Pick" ? "row" : "row-reverse",
                    }}>
                    {hero?.boosters.map((booster) => {
                      return (
                        <div key={booster.id}>
                          <ToggleBetButtonSC
                            disabled={
                              !isLoggedIn || boosters.length > 0 || serie.suspendBoosters || serie.resolvedBoosters
                            }
                            active={
                              boosterButton.some((item) => item.id === booster.id) ||
                              boosters.some((item) => item.booster.id === booster.id)
                            }
                            resolved={booster.status !== 0}
                            won={booster.status === 1}
                            onClick={() =>
                              setBoosterButton((prev) => {
                                if (prev.some((item) => item.id === booster.id)) {
                                  return prev.filter((item) => item.id !== booster.id);
                                } else if (boosterButton.length >= 2) {
                                  return [...prev];
                                } else if (prev.some((item) => item.heroId === hero.hero.id)) {
                                  return [
                                    ...prev.filter((item) => item.heroId !== hero.hero.id),
                                    {
                                      id: booster.id,
                                      coefficient: booster?.coefficient,
                                      type: booster?.name,
                                      heroId: hero.hero.id,
                                    },
                                  ];
                                } else if (prev.some((item) => item.type === booster.name)) {
                                  return [
                                    ...prev.filter((item) => item.type !== booster.name),
                                    {
                                      id: booster.id,
                                      coefficient: booster?.coefficient,
                                      type: booster?.name,
                                      heroId: hero.hero.id,
                                    },
                                  ];
                                } else {
                                  return [
                                    ...prev,
                                    {
                                      id: booster.id,
                                      coefficient: booster?.coefficient,
                                      type: booster?.name,
                                      heroId: hero.hero.id,
                                    },
                                  ];
                                }
                              })
                            }>
                            <span>{booster?.coefficient}</span>
                            <span>x</span>
                          </ToggleBetButtonSC>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <ButtonAddBoosterSC disabled={boosters.length > 0 || serie.suspendBoosters} onClick={handleAddBooster}>
            {isLoggedIn ? t("add-booster") : t("login-add-booster")}
          </ButtonAddBoosterSC>
        </PickBanWrapper>
      )}

      {smallbarItem !== "Pick & Ban" && (
        <>
          <BetWrapperSC>
            {displayMarkets.length > 0 && (
              <>
                {smallbarItem === "Ancient" && (
                  <DescriptionTextSC>
                    <p className="desc-text">
                      {readMoreExpanded
                        ? t("votecard-description-ancient")
                        : t("votecard-description-ancient").length <= descriptionLength
                        ? t("votecard-description-ancient")
                        : `${t("votecard-description-ancient").slice(0, descriptionLength)}...`}
                    </p>
                    {t("votecard-description-ancient").length > descriptionLength && (
                      <button className="read-more" onClick={handleReadMore}>
                        {readMoreExpanded ? t("read-less") : t("read-more")}
                      </button>
                    )}
                  </DescriptionTextSC>
                )}
                {smallbarItem === "Players" && (
                  <DescriptionTextSC>
                    <p className="desc-text">
                      {readMoreExpanded
                        ? t("votecard-description-players")
                        : t("votecard-description-players").length <= descriptionLength
                        ? t("votecard-description-players")
                        : `${t("votecard-description-players").slice(0, descriptionLength)}...`}
                    </p>
                    {t("votecard-description-players").length > descriptionLength && (
                      <button className="read-more" onClick={handleReadMore}>
                        {readMoreExpanded ? t("read-less") : t("read-more")}
                      </button>
                    )}
                  </DescriptionTextSC>
                )}
                {smallbarItem === "Towers" && (
                  <DescriptionTextSC>
                    <p className="desc-text">
                      {readMoreExpanded
                        ? t("votecard-description-towers")
                        : t("votecard-description-towers").length <= descriptionLength
                        ? t("votecard-description-towers")
                        : `${t("votecard-description-towers").slice(0, descriptionLength)}...`}
                    </p>
                    {t("votecard-description-towers").length > descriptionLength && (
                      <button className="read-more" onClick={handleReadMore}>
                        {readMoreExpanded ? t("read-less") : t("read-more")}
                      </button>
                    )}
                  </DescriptionTextSC>
                )}
              </>
            )}

            {displayMarkets.length === 0 && (
              <EmptyMarketsContainerSC>
                <h3>{t("no-markets")}</h3>
              </EmptyMarketsContainerSC>
            )}

            {displayMarkets.length > 0 && (
              <MarketsSC>
                {displayMarkets.map((market) => {
                  return (
                    <MarketSC key={market.id} resolved={market.resolved} suspended={market.suspended}>
                      {!market.hidden && (
                        <>
                          <h3 className="market-name">{market.name}</h3>
                          <div className="market-options">
                            {market.marketOptions.length > 0 &&
                              market.marketOptions.map((marketOption) => {
                                return (
                                  <MarketButtonSC
                                    key={marketOption.id}
                                    active={betButton?.id === marketOption.id}
                                    won={market.resolved && marketOption.status === 1}
                                    disabled={market.suspended || market.resolved}
                                    onClick={() =>
                                      setBetButton((prev) => {
                                        if (prev.id === marketOption.id) {
                                          setPotentionalWin(0.0);
                                          setPotentionalLose(0.0);
                                          return {
                                            id: -1,
                                            coefficient: 0,
                                            name: "",
                                            status: null,
                                            suspended: false,
                                            hidden: false,
                                          };
                                        } else {
                                          return {
                                            id: marketOption.id,
                                            coefficient: marketOption.coefficient,
                                            name: marketOption.name,
                                            status: marketOption.status,
                                            suspended: market.suspended,
                                            hidden: market.hidden,
                                          };
                                        }
                                      })
                                    }>
                                    <span>{marketOption.coefficient}</span>
                                    <span className="button-icon" />
                                    <span>{marketOption.name}</span>
                                  </MarketButtonSC>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </MarketSC>
                  );
                })}
              </MarketsSC>
            )}

            <BuffsWrapperSC>
              {boosters?.length === 0 && !loadingBoosters ? (
                <div className="row">
                  <div className="row-inner">
                    <h3 className="row-text">{t("booster")}</h3>
                    <div className="exclamation-icon" />
                  </div>

                  <div className="buttons">
                    <p className="booster" onClick={() => setSmallbarItem("Pick & Ban")}>
                      {t("pick-booster")}
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {serie.resolvedBoosters && (
                    <>
                      {!serie.resolvedBoosters && (
                        <div className="row">
                          <h3 className="row-text">Choose booster</h3>
                        </div>
                      )}
                      <div className="row" style={{ paddingBottom: "12px" }}>
                        {boosters?.length > 0 &&
                          boosters.map((booster) => {
                            return (
                              <React.Fragment key={booster.id}>
                                {booster.booster.status === 1 && (
                                  <BoosterButtonSC
                                    disabled={booster.used}
                                    className={booster.booster.name === "Pick" ? "pick" : "ban"}
                                    active={pickedBooster?.id === booster.id}
                                    onClick={() =>
                                      setPickedBooster((prev) => {
                                        if (prev.id === booster.id) {
                                          return { id: -1, name: "", coefficient: 0 };
                                        } else {
                                          return {
                                            id: booster.id,
                                            name: booster.booster.name,
                                            coefficient: booster.booster.coefficient,
                                          };
                                        }
                                      })
                                    }>
                                    {booster.booster.name} {booster.booster.coefficient}x
                                  </BoosterButtonSC>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </>
                  )}
                </>
              )}

              <div className="row">
                <h3 className="row-text">{t("potential-win")}</h3>
                {pickedBooster.id !== -1 ? (
                  <h3 className="boosted" style={{ color: "gold" }}>
                    Boosted
                  </h3>
                ) : (
                  <h3 className="boosted" style={{ color: "gold" }}>
                    {potentionalWin} {t("currency")}
                  </h3>
                )}
              </div>
              <div className="row">
                {pickedBooster.id === -1 && <h3 className="row-text">{t("potential-lose")}</h3>}
                {pickedBooster.id === -1 && (
                  <h3 className="boosted" style={{ color: "#FF2121" }}>
                    {potentionalLose} {t("currency")}
                  </h3>
                )}
              </div>
              {pickedBooster.id !== -1 && (
                <div className="row" style={{ paddingTop: "4px" }}>
                  <TotalItemSC win={true}>
                    <div className="description">
                      <div className="lines">
                        <span className="vertical" />
                        <span className="horizontal" />
                      </div>
                      <p className="text">{t("if-vote-wins")} </p>
                    </div>
                    <p className="sub-total">
                      <span className="item-amount">{potentionalWin}</span>
                      <span className="currency">{t("currency")}</span>
                    </p>
                  </TotalItemSC>
                  <TotalItemSC win={false}>
                    <div className="description">
                      <div className="lines">
                        <span className="vertical" />
                        <span className="horizontal" />
                      </div>
                      <p className="text">{t("if-vote-loses")}</p>
                    </div>
                    <p className="sub-total">
                      <span className="item-amount">{potentionalLose}</span>
                      <span className="currency">{t("currency")}</span>
                    </p>
                  </TotalItemSC>
                </div>
              )}
            </BuffsWrapperSC>
            <ButtonConfirmSC disabled={displayMarkets.length === 0} onClick={handleVote}>
              {isLoggedIn ? t("place-a-vote") : t("login-to-vote")}
            </ButtonConfirmSC>
          </BetWrapperSC>
        </>
      )}
    </CardSC>
  );
};

export default VoteCard;
