import create from "zustand";

export const useUsersStore = create((set) => ({
  isLoadingUser: false,
  user: null,
  userError: null,
  isLoadingUpdateUser: false,
  isLoadingFollow: false,
  isFollowSuccess: false,
  followError: null,
  isLoadingUnfollow: false,
  isUnfollowSuccess: false,

  getUserById: async (id, toast, t) => {
    try {
      set({ isLoadingUser: true });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/user/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const responseJson = await response.json();
      if (responseJson?.data?.response === "USER.NOT_FOUND") {
        set({ userError: "USER.NOT_FOUND" });
        toast.error(t("toast-error-user-not-found"));
        throw Error("User not found");
      }
      set({ isLoadingUser: false, user: responseJson });
    } catch (error) {
      console.log("get user by id: ", error);
      set({ isLoadingUser: false });
    }
  },

  follow: async (followed, follower, token) => {
    try {
      set({ isLoadingFollow: true });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/user/follow`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: followed,
          followerId: follower,
        }),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        set({ followError: responseJson.message, isFollowSuccess: true });
        throw Error(response.statusText);
      }
      set({ isLoadingFollow: false });
    } catch (error) {
      console.log("follow error: ", error);
      set({ isLoadingFollow: false });
    }
  },

  unfollow: async (followed, follower, token) => {
    try {
      set({ isLoadingFollow: true });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/user/unfollow`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: followed,
          followerId: follower,
        }),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        set({ followError: responseJson.message, isUnfollowSuccess: true });
        throw Error(response.statusText);
      }
      set({ isLoadingUnfollow: false });
    } catch (error) {
      console.log("follow error: ", error);
      set({ isLoadingUnfollow: false });
    }
  },
}));
