import styled from "styled-components";

import DotaSVG from "../../assets/icons/dota_icn.svg";

export const OuterWrapperSC = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const WrapperSC = styled.div`
  width: 100%;
  display: flex;
  max-height: 86px;
`;

export const LiveBarTitle = styled.div`
  text-align: center;
  background-color: #c00000;

  padding: 18px 8px 12px 8px;
  h2 {
    font-family: ${({ theme }) => theme.fontMain};
    letter-spacing: 1px;
    font-size: 30px;
    font-weight: 100;
    line-height: 30px;
    color: #fff;

    margin-bottom: 8px;
  }
  .center {
    font-size: 16px;
    color: #cbd2d9;
    letter-spacing: 1px;
    line-height: 18px;
    font-family: ${({ theme }) => theme.fontMain};
  }
`;

export const LiveBarWrapperSC = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  overflow-x: scroll;
  .no-games {
    align-self: center;
    color: ${({ theme }) => theme.neutral1};
    margin-left: 16px;
  }
`;

export const CardWrapperSC = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 246px;

  &:not(:last-of-type) {
    .info {
      margin-right: 16px;
    }
  }
  .tournament-wrapper {
    border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
    p {
      color: ${({ theme }) => theme.primary};
      margin-left: 8px;
    }
  }
  .info-wrapper {
    border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
  }
  .info {
    display: flex;
    gap: 8px;
    align-items: center;
    .team-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
      .team-info {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        .team-name {
          color: ${({ theme }) => theme.neutral3};
        }
        .team-score {
          color: ${({ theme }) => theme.neutral4};
          flex: 1;
          text-align: end;
        }
      }
    }
  }
  .dota-icn {
    width: 40px;
    height: 40px;
    -webkit-mask: url(${DotaSVG}) no-repeat center;
    mask: url(${DotaSVG}) no-repeat center;
    background-color: ${({ theme }) => theme.danger};
    margin-left: 4px;
  }
`;
