import styled from "styled-components";

export const TitleSC = styled.h1`
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 45px;
  padding-top: 105px;
  padding-bottom: 64px;
`;

export const SubtitleSC = styled.h2`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 28px;
  margin-top: 40px;
`;

export const TextSC = styled.p`
  font-family: ${({ theme }) => theme.fontSub};
  color: ${({ theme }) => theme.neutral3};
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;
`;

export const TextItalicSC = styled.p`
  font-family: ${({ theme }) => theme.fontSub};
  color: ${({ theme }) => theme.neutral3};
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;
  font-style: italic;
  padding: 12px;
  background-color: #fac93c;
  color: #1b2a31;
  border-radius: 10px;
`;
