import * as yup from "yup";

export const registerSchema = yup.object().shape({
  name: yup
    .string()
    .required("name-required")
    .test("len", "name-min-chars", (val) => val.length >= 3),
  email: yup.string().email("mail-format").required("mail-required"),
  password: yup
    .string()
    .required("password-required")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, "password-format"),
  termsOfService: yup.boolean().required("terms-required").oneOf([true], "terms-required"),
});
