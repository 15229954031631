import styled from "styled-components";
import accountIcon from "../assets/icons/account.svg";

export const MainSC = styled.main`
  margin-top: 80px;
  min-height: calc(100vh - 80px - 140px - 140px);

  @media (max-width: 1023px) {
    min-height: calc(100vh - 80px - 140px - 370px);
  }

  @media (max-width: 1023px) {
    min-height: calc(100vh - 80px - 140px - 776px);
  }
`;

export const ContainerSC = styled.div`
  max-width: calc(1360px);
  margin: auto;
  height: 100%;
  width: 100%;

  @media (max-width: 1440px) {
    max-width: calc(100vw - 80px);
  }

  @media (max-width: 767px) {
    max-width: calc(100vw - 40px);
  }
`;

export const NoIconSC = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: ${({ theme }) => theme.body};
  border: ${({ theme }) => `2px solid ${theme.primary}`};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .no-icon {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${accountIcon}) no-repeat center;
    mask: url(${accountIcon}) no-repeat center;
    background-color: ${({ theme }) => theme.neutral3};
    border-radius: 50px;
    object-fit: cover;
  }
`;

export const CommonWrapperSC = styled.div`
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none !important;
`;
