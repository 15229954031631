import create from "zustand";

export const useLanguageStore = create((set) => ({
  languages: ["en", "es", "zh"],
  language: "en",

  getLanguage: () => {
    const storedLang = localStorage.getItem("grid-language");
    if (storedLang) {
      set({ language: storedLang });
    }
  },

  setLanguage: (lang) => {
    localStorage.setItem("grid-language", lang);
    set({ language: lang });
  },
}));
