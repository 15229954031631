import styled from "styled-components";

import IconBanSVG from "../../assets/icons/ban-icon.svg";
import IconBan2SVG from "../../assets/icons/ban-2.svg";
import IconPickSVG from "../../assets/icons/pick-icon.svg";
import IconWinSVG from "../../assets/icons/win-icon.svg";
import IconJoySVG from "../../assets/icons/joy-icon.svg";
import IconTargetSVG from "../../assets/icons/target-icon.svg";
import IconFlasterSVG from "../../assets/icons/flaster-icon.svg";
import IconPick2SVG from "../../assets/icons/finger.svg";
import ThunderIconSVG from "../../assets/icons/thunder-icon.svg";
// import CookieIconSvg from "../../assets/icons/cookie-icon.svg";
// import IconExclamationSVG from "../../assets/icons/exclamation.svg";

export const CardSC = styled.div`
  width: 440px;
  background-color: ${({ theme }) => theme.body};
  border-radius: 30px;
  border: ${({ theme }) => `1px solid ${theme.neutral1}`};
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 26px 60px;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    padding: 26px 22px;
  }
`;

export const TournamentInfoSC = styled.h3`
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontSub};
`;

export const LogosAndResultSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const TeamLeftSC = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50px;
  border: ${({ teamColor }) => `3px solid ${teamColor}`};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
`;

export const TeamRightSC = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50px;
  border: ${({ teamColor }) => `3px solid ${teamColor}`};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
`;

export const ResultSC = styled.div`
  width: 106px;
  display: flex;
  justify-content: center;
  position: relative;

  h3,
  span {
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    color: ${({ theme }) => theme.neutral4};
    font-family: ${({ theme }) => theme.fontSub};
  }

  .live-status {
    position: absolute;
    top: 42px;
    width: 50px;
    height: 20px;
    border: ${({ theme }) => `1px solid ${theme.danger}`};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.body};
    margin-top: 4px;

    .play {
      width: 7px;
      height: 8px;
      clip-path: polygon(0 0, 0 100%, 100% 50%);
      background-color: ${({ theme }) => theme.danger};
      margin-left: 6px;
    }

    p {
      color: ${({ theme }) => theme.danger};
      font-size: 11px;
      font-weight: 700;
      margin-right: 6px;
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.fontSub};
    }
  }
`;

export const TeamNamesSC = styled.div`
  display: flex;
  margin-bottom: 32px;

  h3 {
    flex: 1;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.neutral4};

    &.team-left {
      text-align: right;
      margin-right: 53px;
    }

    &.team-right {
      text-align: left;
      margin-left: 53px;
    }
  }
`;

export const EmptyMarketsContainerSC = styled.div`
  margin-bottom: 24px;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: ${({ theme }) => theme.neutralHard};
    margin: 0;
    margin-bottom: 16px;
  }
`;

export const SmallbarListSC = styled.ul`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
  margin: 0 0 26px;
`;

export const SmallbarItemSC = styled.li`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
  border-bottom: ${({ theme, active }) => (active ? `2px solid ${theme.primary}` : `2px solid ${theme.body}`)};
  cursor: pointer;
  min-width: ${({ active }) => (active ? "100px" : "22px")};

  :not(:last-of-type) {
    margin-right: 12px;
  }

  p {
    color: ${({ theme, active }) => (active ? theme.primary : theme.neutral4)};
    font-size: 16px;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontMain};
    display: ${({ active }) => (active ? "block" : "none")};
    margin-left: 10px;
  }

  .smallbar-icon {
    background-color: ${({ theme, active }) => (active ? theme.primary : theme.neutral3)};
  }

  .smallbar-icon.ban {
    width: 21px;
    height: 21px;
    -webkit-mask: url(${IconBanSVG}) no-repeat center;
    mask: url(${IconBanSVG}) no-repeat center;
  }

  .smallbar-icon.pick {
    width: 21px;
    height: 21px;
    -webkit-mask: url(${IconPickSVG}) no-repeat center;
    mask: url(${IconPickSVG}) no-repeat center;
  }

  .smallbar-icon.win {
    width: 22px;
    height: 21px;
    -webkit-mask: url(${IconWinSVG}) no-repeat center;
    mask: url(${IconWinSVG}) no-repeat center;
  }

  .smallbar-icon.joy {
    width: 21px;
    height: 17px;
    -webkit-mask: url(${IconJoySVG}) no-repeat center;
    mask: url(${IconJoySVG}) no-repeat center;
  }

  .smallbar-icon.target {
    width: 21px;
    height: 21px;
    -webkit-mask: url(${IconTargetSVG}) no-repeat center;
    mask: url(${IconTargetSVG}) no-repeat center;
  }

  .smallbar-icon.flaster {
    width: 21px;
    height: 21px;
    -webkit-mask: url(${IconFlasterSVG}) no-repeat center;
    mask: url(${IconFlasterSVG}) no-repeat center;
  }
`;

export const DescriptionTextSC = styled.div`
  margin-bottom: 12px;
  margin-top: -12px;

  p.desc-text {
    font-family: ${({ theme }) => theme.fontMain};
    color: ${({ theme }) => theme.neutral3} !important;
    font-weight: 500;
    font-size: 11px;
    display: inline;
    margin-right: 8px;
  }

  button.read-more {
    background-color: transparent;
    font-family: ${({ theme }) => theme.fontSub};
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const BetWrapperSC = styled.div``;

export const MarketsSC = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 8px;
`;

export const MarketSC = styled.div`
  .market-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: ${({ theme, suspended, resolved }) => (suspended || resolved ? theme.neutral2 : theme.neutral4)};
    margin: 0;
    margin-bottom: 16px;
  }

  .market-options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    button:not(:last-of-type) {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    margin-bottom: 16px;
  }
`;

export const MarketButtonSC = styled.button`
  height: 30px;
  min-width: 64px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) => (active ? theme.primary : "white")};
  background-color: ${({ won }) => won && "green"};
  border: ${({ theme, active }) => (active ? `1px solid ${theme.primary}` : `1px solid ${theme.body}`)};
  border-radius: 8px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  span {
    font-family: ${({ theme }) => theme.fontMain};
    font-size: 11px;
    font-weight: 500;
    color: ${({ active }) => (active ? "white" : "black")};
    color: ${({ won }) => won && "white"};
  }

  .button-icon {
    width: 16px;
    height: 16px;
    transform: scale(1.1);
    margin: 0 4px;
    -webkit-mask: url(${ThunderIconSVG}) no-repeat center;
    mask: url(${ThunderIconSVG}) no-repeat center;
    background-color: ${({ theme, active }) => (active ? "white" : "black")};
    background-color: ${({ won }) => won && "white"};
  }

  :hover:not(:disabled) {
    background-color: ${({ theme, active }) => (active ? theme.primary : "white")};
    color: ${({ theme, active }) => (active ? theme.body : "black")};
    border: ${({ theme }) => `1px solid ${theme.primary}`};

    span {
      color: ${({ theme, active }) => (active ? "white" : "black")};
    }

    .button-icon {
      background-color: ${({ theme, active }) => (active ? "white" : "black")};
    }
  }

  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme, won }) => !won && theme.neutral2};
  }
`;

export const ToggleBetButtonSC = styled.button`
  height: 30px;
  min-width: 64px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) => (active ? theme.primary : "white")};
  background-color: ${({ won }) => won && "#00B87E !important"};
  background-color: ${({ won, active }) => active && won && "#78BB6C !important"};
  background-color: ${({ won, active, resolved, theme }) => active && resolved && !won && `${theme.danger} !important`};

  border: ${({ theme, active }) => (active ? `1px solid ${theme.primary}` : `1px solid ${theme.body}`)};
  border: ${({ won }) => won && "1px solid #00B87E !important"};
  border: ${({ won, active }) => active && won && "1px solid #78BB6C !important"};
  border: ${({ won, active, resolved, theme }) => active && resolved && !won && `1px solid ${theme.danger} !important`};

  border-radius: 8px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  :disabled {
    background-color: ${({ theme, active }) => !active && theme.neutral2};
    cursor: not-allowed;
  }
  span {
    font-family: ${({ theme }) => theme.fontMain};
    font-size: 11px;
    font-weight: 500;
    color: ${({ theme }) => theme.neutral4};
    color: ${({ theme, active, won }) => !won && !active && `${theme.neutral1} !important`};
  }

  .button-icon {
    width: 16px;
    height: 16px;
    transform: scale(1.1);
    margin: 0 4px;
    -webkit-mask: url(${ThunderIconSVG}) no-repeat center;
    mask: url(${ThunderIconSVG}) no-repeat center;
    background-color: ${({ theme, active }) => (active ? "white" : "black")};
  }

  :hover:not(:disabled) {
    background-color: ${({ theme, active }) => (active ? theme.primary : "white")};
    color: ${({ theme, active }) => (active ? theme.body : "black")};
    border: ${({ theme }) => `1px solid ${theme.primary}`};

    span {
      color: ${({ theme, active }) => (active ? "white" : "black")};
    }

    .button-icon {
      background-color: ${({ theme, active }) => (active ? "white" : "black")};
    }
  }
`;

export const BuffsWrapperSC = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    justify-content: space-between;
    height: auto;
    padding: 0 12px;
    background-color: ${({ theme }) => `${theme.neutral3}33`};

    .booster {
      font-family: Futura;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      cursor: pointer;
      color: ${({ theme }) => `${theme.primary}`};
    }

    &:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-bottom: 10px;
    }

    .row-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: ${({ theme }) => theme.neutral3};
      font-family: ${({ theme }) => theme.fontMain};
    }
  }

  .total {
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => `${theme.neutral4}`};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontSub};
  }

  .boosted {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontMain};
  }
`;

export const BoosterButtonSC = styled.button`
  height: 30px;
  min-width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fontSub};
  color: ${({ theme, active }) => (active ? "white" : theme.neutral3)};
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }

  &.pick {
    background-color: ${({ active }) => (active ? "green" : "transparent")};
    border: 1px solid green;

    :disabled {
      border: ${({ theme }) => `1px solid ${theme.neutral3}`};
    }
  }

  &.ban {
    background-color: ${({ active }) => (active ? "red" : "transparent")};
    border: 1px solid red;

    :disabled {
      border: ${({ theme }) => `1px solid ${theme.neutral3}`};
    }
  }

  &.game {
    background-color: ${({ active }) => (active ? "royalblue" : "transparent")};
    border: 1px solid royalblue;

    :disabled {
      border: ${({ theme }) => `1px solid ${theme.neutral3}`};
    }
  }
`;

export const TotalItemSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  :not(:first-of-type) {
    margin-top: -2px;
  }

  .description {
    display: flex;
    align-items: center;

    .lines {
      border: ${({ theme }) => `1px solid ${theme.neutral3}`};
      width: 14px;
      position: relative;

      .vertical {
        position: absolute;
        border-left: ${({ theme }) => `1px solid ${theme.neutral3}`};
        height: 14px;
        margin-top: -14px;
        margin-left: -1px;
      }

      .horizontal {
        position: absolute;
      }
    }

    .text {
      margin-left: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: ${({ theme }) => theme.neutral3};
      font-family: ${({ theme }) => theme.fontSub};
    }
  }

  .sub-total {
    span {
      font-family: ${({ theme }) => theme.fontSub};
      font-size: 14px;
    }

    .item-amount {
      color: ${({ win }) => (win ? "green" : "red")};
      margin-right: 4px;
    }

    .currency {
      color: ${({ theme }) => theme.neutral4};
      text-transform: uppercase;
    }
  }

  :not(:last-of-type) {
    .description .lines .vertical {
      height: 28px;
    }
  }
`;

export const ButtonConfirmSC = styled.button`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: ${({ theme }) => `1px solid ${theme.primary}`};
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

///////////////////////////////////////
//////// Pick & Ban //////////////////
///////////////////////////////////////

export const PickBanWrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 15px;
    margin-top: -10px;
    border-bottom: 1px solid ${({ theme }) => theme.neutral3};
  }

  .pick-ban-buttons {
    display: flex;
    flex-direction: column;
  }

  .pick-ban {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hero {
      display: flex;
      align-items: center;

      .hero-image {
        width: 32px;
        height: 32px;
        border-radius: 50px;
        border: ${({ theme }) => `2px solid ${theme.primary}`};
        object-fit: cover;
        margin-right: 16px;
      }

      .hero-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.neutral4};
      }
    }

    .boosters {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    :not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  p {
    font-family: "Futura";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.neutral4};
  }

  .right-part {
    display: flex;
    gap: 25px;
    align-items: center;

    .inner {
      display: flex;
      align-items: center;
      gap: 8px;
      .pick-icon {
        background-color: ${({ theme }) => theme.neutral4};
        width: 16px;
        height: 16px;
        -webkit-mask: url(${IconPick2SVG}) no-repeat center;
        mask: url(${IconPick2SVG}) no-repeat center;
      }
      .ban-icon {
        background-color: ${({ theme }) => theme.neutral4};
        width: 16px;
        height: 16px;
        -webkit-mask: url(${IconBan2SVG}) no-repeat center;
        mask: url(${IconBan2SVG}) no-repeat center;
      }
    }
  }
`;

export const ButtonAddBoosterSC = styled.button`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.primary};
  border: ${({ theme }) => `1px solid ${theme.primary}`};
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 20px;

  :disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.neutral3};
    border: ${({ theme }) => `1px solid ${theme.neutral3}`};
  }
`;
