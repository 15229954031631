import { useTranslation } from "react-i18next";
import { MoonIconSC, ThemeTogglerBackgroundSC, ThemeTogglerDotSC, ThemeTogglerWrapperSC } from "./styled";

const ThemeToggler = ({ theme, toggleTheme }) => {
  const { t } = useTranslation();
  return (
    <ThemeTogglerWrapperSC>
      <p>{theme === "light" ? t("theme-mode-dark") : t("theme-mode-light")}</p>
      <ThemeTogglerBackgroundSC onClick={toggleTheme}>
        {theme === "light" ? <ThemeTogglerDotSC /> : <MoonIconSC />}
      </ThemeTogglerBackgroundSC>
    </ThemeTogglerWrapperSC>
  );
};

export default ThemeToggler;
