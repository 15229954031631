import Modal from "../../components/Modal";
import { AvatarImageSC, ContentSC, SubmitButtonSC, TitleSC } from "./styled";
import { toast } from "react-toastify";

import { Grid } from "@mui/material";
import { useAuthStore } from "../../stores/auth";
import { useTranslation } from "react-i18next";

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const ModalAddAvatar = ({ isOpen, setIsOpen, setAvatarUrl, avatarUrl }) => {
  const { token, userDetails, updateUserDetails } = useAuthStore();
  const { t } = useTranslation();
  const images = importAll(require.context("../../assets/icons/hero-avatars", false, /\.(png|jpe?g|svg)$/));

  const handleSubmit = async () => {
    const result = await updateUserDetails(token, avatarUrl, userDetails.buffs, userDetails.twitter);
    if (result) {
      toast.success(t("toast-success-update-user"));
    }
    setAvatarUrl("");
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} setIsOpen={setIsOpen}>
      <ContentSC style={{ maxWidth: "600px" }}>
        <TitleSC>{t("add-avatar")}</TitleSC>
        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
          {Object.keys(images).map((image, index) => {
            return (
              <Grid item key={index}>
                <AvatarImageSC
                  src={require(`../../assets/icons/hero-avatars/${image}`)}
                  alt={image}
                  avatarUrl={avatarUrl}
                  onClick={() => {
                    setAvatarUrl(image);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <SubmitButtonSC onClick={handleSubmit}>{t("save")}</SubmitButtonSC>
      </ContentSC>
    </Modal>
  );
};

export default ModalAddAvatar;
