import * as yup from "yup";

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("password-required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "password-format",
    ),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "password-mismatch"),
});
