import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUsersStore } from "../../stores/users";
import { useAuthStore } from "../../stores/auth";

import { ContainerSC } from "../../components/commonSC";
import ModalAddTwitter from "./ModalAddTwitter";
import ModalEditTwitter from "./ModalEditTwitter";
import { TwitterShareButton } from "react-share";

import { AccordionDetails, AccordionSummary, useMediaQuery } from "@mui/material";
import { Tooltip } from "@mui/material";
import {
  AccordionArrowSC,
  AccordionDetailsTextSC,
  AccordionMuiSC,
  AccordionSummaryTextSC,
  AccordionWrapperSC,
  BadgeItemSC,
  ButtonShareSC,
  ProfileSectionSC,
  ProfileStatisticsSC,
  ShareWrapperSC,
} from "./styled";
import ModalAddAvatar from "./ModalAddAvatar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isModalTwitterOpen, setIsModalTwitterOpen] = useState(false);
  const [isModalAvatarOpen, setIsModalAvatarOpen] = useState(false);
  const [isModalTwitterEditOpen, setIsModalTwitterEditOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isPanel1Expanded, setIsPanel1Expanded] = useState(false);
  const [isPanel2Expanded, setIsPanel2Expanded] = useState(false);
  const [isPanel3Expanded, setIsPanel3Expanded] = useState(false);
  const matches768Up = useMediaQuery("(min-width:768px)");
  const { getUserById, user, userError, follow, unfollow } = useUsersStore();
  const { userDetails, isLoggedIn, token, getUserDetails } = useAuthStore();
  const { t } = useTranslation();

  const handleChange = (panel) => (event, newExpandedRow) => {
    if (panel === "panel1") {
      setIsPanel1Expanded((prev) => !prev);
    }
    if (panel === "panel2") {
      setIsPanel2Expanded((prev) => !prev);
    }
    if (panel === "panel3") {
      setIsPanel3Expanded((prev) => !prev);
    }
  };

  const handleAddTwitter = () => {
    setIsModalTwitterOpen(true);
  };

  const handleEditTwitter = () => {
    setIsModalTwitterEditOpen(true);
  };

  const handleAddAvatar = () => {
    setIsModalAvatarOpen(true);
  };

  const handleFollow = async () => {
    if (user && userDetails && token) {
      await follow(user.id, userDetails.id, token);
      getUserById(userId);
    }
  };

  const handleUnfollow = async () => {
    if (user && userDetails && token) {
      await unfollow(user.id, userDetails.id, token);
      getUserById(userId);
    }
  };

  useEffect(() => {
    getUserById(userId, toast, t);
    getUserDetails(token);
    //eslint-disable-next-line
  }, [userId, navigate, t]);

  useEffect(() => {
    if (user) {
      if (
        user.badges?.filter((badge) => badge.type === "trophies" && badge.imageUrl?.startsWith("http")).length !== 0
      ) {
        setIsPanel1Expanded(true);
      }
      if (user.badges?.filter((badge) => badge.type === "special" && badge.imageUrl?.startsWith("http")).length !== 0) {
        setIsPanel2Expanded(true);
      }
      if (user.badges?.filter((badge) => badge.type === "unique" && badge.imageUrl?.startsWith("http")).length !== 0) {
        setIsPanel3Expanded(true);
      }
    }
  }, [user]);

  return (
    <ContainerSC>
      {user && !userError && (
        <>
          <ProfileSectionSC>
            <header>
              <div className="header-content">
                <div className="header-inner">
                  <div className="rank">{user.rank}</div>
                  <div className="name-and-stats">
                    <h1>{user.name}</h1>
                    {matches768Up && (
                      <div className="stats-and-twitter">
                        <div className="stats">
                          <h2>
                            {t("level")} {user.level}
                          </h2>
                          <h2>
                            {user.buffs} {t("currency")}
                          </h2>
                        </div>
                        {user.twitter && userDetails?.id !== user.id && (
                          <a
                            href={`https://twitter.com/${user.twitter.substring(1)}`}
                            target="_blank"
                            rel="noreferrer noopener">
                            <div className="twitter">
                              <span className="twitter-icon" />
                              <p className="add-twitter">{user.twitter}</p>
                            </div>
                          </a>
                        )}
                        {isLoggedIn && !userDetails.twitter && userDetails?.id === user.id && (
                          <div className="twitter twitter-add" onClick={handleAddTwitter}>
                            <span className="twitter-icon" />
                            <p className="add-twitter">{t("add-twitter")}</p>
                          </div>
                        )}
                        {isLoggedIn && userDetails.twitter && userDetails?.id === user.id && (
                          <div style={{ display: "flex", alignItems: "center", height: "24px" }}>
                            <a
                              href={`https://twitter.com/${userDetails.twitter.substring(1)}`}
                              target="_blank"
                              rel="noreferrer noopener">
                              <div className="twitter twitter-add">
                                <span className="twitter-icon" />
                                <p className="add-twitter">{userDetails.twitter}</p>
                              </div>
                            </a>
                            <span className="edit-icon" onClick={handleEditTwitter} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {!matches768Up && (
                  <div className="stats-and-twitter">
                    <div className="stats">
                      <h2>
                        {t("level")} {user.level}
                      </h2>
                      <h2>
                        {user.buffs} {t("currency")}
                      </h2>
                    </div>
                    {user.twitter && userDetails?.id !== user.id && (
                      <div className="twitter twitter-add" onClick={handleAddTwitter}>
                        <span className="twitter-icon" />
                        <p className="add-twitter">{user.twitter}</p>
                      </div>
                    )}
                    {isLoggedIn && !userDetails.twitter && userDetails?.id === user.id && (
                      <div className="twitter twitter-add" onClick={handleAddTwitter}>
                        <span className="twitter-icon" />
                        <p className="add-twitter">{"add twitter account"}</p>
                      </div>
                    )}
                    {isLoggedIn && userDetails.twitter && userDetails?.id === user.id && (
                      <div style={{ display: "flex", alignItems: "center", height: "24px", marginTop: "4px" }}>
                        <a
                          href={`https://twitter.com/${userDetails.twitter.substring(1)}`}
                          target="_blank"
                          rel="noreferrer noopener">
                          <div className="twitter twitter-add">
                            <span className="twitter-icon" />
                            <p className="add-twitter">{userDetails.twitter}</p>
                          </div>
                        </a>
                        <span className="edit-icon" onClick={handleEditTwitter} />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {isLoggedIn && userDetails?.id !== user.id && (
                <button className="button-back" onClick={() => navigate("/leaderboard")}>
                  Back to players
                </button>
              )}
              {userDetails?.id === user.id && (
                <ShareWrapperSC>
                  <TwitterShareButton
                    url={`https://development.d1qg8udksqda3b.amplifyapp.com/user/${userDetails.id}`}
                    title={"Check this on @Gridgg"}>
                    <ButtonShareSC />
                  </TwitterShareButton>
                </ShareWrapperSC>
              )}
            </header>

            <ProfileStatisticsSC>
              <div className="row-1">
                {user.badges?.map((badge, index) => {
                  if (badge.type === "unique") {
                    return (
                      <div className="badge" key={index}>
                        {badge.shortDescription}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className="row-aside">
                <div className="row-2">
                  {userDetails?.id === user.id && userDetails.avatar && (
                    <img
                      className="image-main"
                      src={require(`../../assets/icons/hero-avatars/${userDetails.avatar}`)}
                      alt="Avatar"
                      onClick={handleAddAvatar}
                    />
                  )}
                  {userDetails?.id === user.id && !userDetails.avatar && (
                    <div className="avatar-container">
                      <p className="add-avatar" onClick={handleAddAvatar}>
                        {t("add-avatar")}
                      </p>
                    </div>
                  )}
                  {userDetails?.id !== user.id && user.avatar && (
                    <img
                      className="image-main"
                      src={require(`../../assets/icons/hero-avatars/${user.avatar}`)}
                      alt="Avatar"
                    />
                  )}
                  {userDetails?.id !== user.id && !user.avatar && (
                    <div className="avatar-container">
                      <div className="no-avatar" />
                    </div>
                  )}
                  <div className="social">
                    <div className="row">
                      <h3>{t("followers")}</h3>
                      <p>{user.followers.length}</p>
                    </div>
                    <div className="row">
                      <h3>{t("following")}</h3>
                      <p>{user.follows.length}</p>
                    </div>
                    <div className="row">
                      {isLoggedIn &&
                        userDetails.id !== user.id &&
                        !user.followers.find((f) => f.id === userDetails.id) && (
                          <button className="follow" onClick={handleFollow}>
                            <span className="icon" />
                            <span className="text">{t("follow")}</span>
                          </button>
                        )}
                      {isLoggedIn && userDetails.id !== user.id && user.followers.find((f) => f.id === userDetails.id) && (
                        <button className="follow" onClick={handleUnfollow}>
                          <span className="icon" />
                          <span className="text">{t("unfollow")}</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row-3">
                  {user.badges
                    ?.filter((badge) => badge.name === "Streak Badge")
                    .map((badge, index) => {
                      return (
                        <div className="badge-wrapper" key={index}>
                          <p className="text-head">{badge.name}</p>
                          <div className="level">
                            {t("level")} {user.level}
                          </div>
                          <div className="level-icon-wrapper">
                            <div className="icon" />
                            <p>{user.level}</p>
                          </div>
                          {badge.toNextLevel ? (
                            <>
                              <p className="text-head">{t("until-next-level")}</p>
                              <p className="until-next-level">
                                {badge.toNextLevel} {t("kills-left")}
                              </p>
                            </>
                          ) : null}
                        </div>
                      );
                    })}

                  <p className="text-head">{t("votes-won-rating")}</p>
                  <div className="dota-row">
                    <div className="icon" />
                    <div className="dota-text">Dota 2</div>
                  </div>
                  <div className="ratio-row">
                    <div className="ratio-value">{user.voteStatistics.winPercentage}%</div>
                    <span>|</span>
                    <div className="ratio-description">{t("win-ratio")}</div>
                  </div>
                  <div className="ratio-row">
                    <div className="ratio-value">{user.voteStatistics.winStreak}</div>
                    <span>|</span>
                    <div className="ratio-description">{t("current-streak")}</div>
                  </div>
                  <div className="ratio-row">
                    <div className="ratio-value">{user.voteStatistics.biggestWinStreak}</div>
                    <span>|</span>
                    <div className="ratio-description">{t("biggest-streak")}</div>
                  </div>
                </div>
                <div className="row-4">
                  <div className="title-container">
                    <h2>USER VOTE LOGS</h2>
                  </div>
                  <div className="vote-logs">
                    {user.votes.length === 0 && <p className="no-votes">{t("no-votes")}</p>}
                    {user.votes.map((vote) => {
                      return (
                        <div className="flex" key={vote.id}>
                          <p className="date">{user?.name}</p>
                          <div className="title">
                            <h2 className="vote-name">
                              &lt;{vote.name.length > 20 ? vote.name.slice(0, 7).concat("...") : vote.name}&gt;&nbsp;
                            </h2>
                            <p className="market">{vote.market.name}</p>
                          </div>
                          {vote.win === true ? (
                            <div className="win-icon" />
                          ) : vote.win === false ? (
                            <div className="lose-icon" />
                          ) : (
                            <div className="progress-icon" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </ProfileStatisticsSC>
          </ProfileSectionSC>

          <AccordionWrapperSC>
            <AccordionMuiSC disableGutters elevation={0} expanded={isPanel1Expanded} onChange={handleChange(`panel1`)}>
              <AccordionSummary
                aria-controls={`panel${1}d-content`}
                id="panel1d-header"
                expandIcon={<AccordionArrowSC />}>
                <AccordionSummaryTextSC>{t("trophies")}</AccordionSummaryTextSC>
              </AccordionSummary>
              <AccordionDetails>
                {user.badges?.filter((badge) => badge.type === "trophies" && badge.imageUrl?.startsWith("http"))
                  .length === 0 ? (
                  <AccordionDetailsTextSC>{t("no-trophies")}</AccordionDetailsTextSC>
                ) : (
                  user.badges
                    ?.filter((badge) => badge.type === "trophies" && badge.imageUrl?.startsWith("http"))
                    .map((badge, index) => {
                      return (
                        <Tooltip
                          title={
                            <>
                              <p style={{ textAlign: "center", fontSize: "14px", marginBottom: "12px" }}>
                                {badge.name}
                              </p>
                              <p style={{ textAlign: "center", fontSize: "10px" }}>{badge.description}</p>
                            </>
                          }
                          placement="top"
                          arrow
                          key={index}>
                          <BadgeItemSC>
                            <img src={badge.imageUrl} alt={badge.shortDescription} />
                          </BadgeItemSC>
                        </Tooltip>
                      );
                    })
                )}
              </AccordionDetails>
            </AccordionMuiSC>

            <AccordionMuiSC disableGutters elevation={0} expanded={isPanel2Expanded} onChange={handleChange(`panel2`)}>
              <AccordionSummary
                aria-controls={`panel${2}d-content`}
                id="panel2d-header"
                expandIcon={<AccordionArrowSC />}>
                <AccordionSummaryTextSC>{t("special-badges")}</AccordionSummaryTextSC>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDetails>
                  {user.badges?.filter((badge) => badge.type === "special" && badge.imageUrl?.startsWith("http"))
                    .length === 0 ? (
                    <AccordionDetailsTextSC>{t("no-special-badges")}</AccordionDetailsTextSC>
                  ) : (
                    user.badges
                      ?.filter((badge) => badge.type === "special" && badge.imageUrl?.startsWith("http"))
                      .map((badge, index) => {
                        return (
                          <Tooltip
                            title={
                              <>
                                <p style={{ textAlign: "center", fontSize: "14px", marginBottom: "12px" }}>
                                  {badge.name}
                                </p>
                                <p style={{ textAlign: "center", fontSize: "10px" }}>{badge.description}</p>
                              </>
                            }
                            placement="top"
                            arrow
                            key={index}>
                            <BadgeItemSC>
                              <img src={badge.imageUrl} alt={badge.shortDescription} />
                            </BadgeItemSC>
                          </Tooltip>
                        );
                      })
                  )}
                </AccordionDetails>
              </AccordionDetails>
            </AccordionMuiSC>

            <AccordionMuiSC disableGutters elevation={0} expanded={isPanel3Expanded} onChange={handleChange(`panel3`)}>
              <AccordionSummary
                aria-controls={`panel${3}d-content`}
                id="panel3d-header"
                expandIcon={<AccordionArrowSC />}>
                <AccordionSummaryTextSC>{t("unique-badges")}</AccordionSummaryTextSC>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionDetailsTextSC>
                  {user.badges?.filter((badge) => badge.type === "unique").length === 0 ? (
                    <AccordionDetailsTextSC>{t("no-unique-badges")}</AccordionDetailsTextSC>
                  ) : (
                    user.badges
                      ?.filter((badge) => badge.type === "unique" && badge.imageUrl?.startsWith("http"))
                      .map((badge, index) => {
                        return (
                          <Tooltip
                            title={
                              <>
                                <p style={{ textAlign: "center", fontSize: "14px", marginBottom: "12px" }}>
                                  {badge.name}
                                </p>
                                <p style={{ textAlign: "center", fontSize: "10px" }}>{badge.description}</p>
                              </>
                            }
                            placement="top"
                            arrow
                            key={index}>
                            <BadgeItemSC>
                              <img src={badge.imageUrl} alt={badge.shortDescription} />
                            </BadgeItemSC>
                          </Tooltip>
                        );
                      })
                  )}
                </AccordionDetailsTextSC>
              </AccordionDetails>
            </AccordionMuiSC>
          </AccordionWrapperSC>
        </>
      )}

      <ModalAddTwitter isOpen={isModalTwitterOpen} setIsOpen={setIsModalTwitterOpen} />
      <ModalEditTwitter isOpen={isModalTwitterEditOpen} setIsOpen={setIsModalTwitterEditOpen} />
      <ModalAddAvatar
        isOpen={isModalAvatarOpen}
        setIsOpen={setIsModalAvatarOpen}
        setAvatarUrl={setAvatarUrl}
        avatarUrl={avatarUrl}
      />
    </ContainerSC>
  );
};

export default UserProfile;
