import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import {
  ContentModalSC,
  DateSC,
  DateWrapperSC,
  DescriptionSC,
  InfoTitleSC,
  InfoWrapperSC,
  TitleSC,
} from "./ReleaseInformation.styled";
import { format } from "date-fns";

const ReleaseInformationModal = ({ modalInfo, setIsOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={modalInfo.isOpen}
      setIsOpen={() => {
        setIsOpen({ isOpen: false });
      }}>
      <ContentModalSC>
        <TitleSC style={{ textAlign: "center" }}>{t("release-information")}</TitleSC>
        <InfoWrapperSC>
          <InfoTitleSC>{modalInfo.title}</InfoTitleSC>
          <DateWrapperSC>
            <DateSC>{modalInfo.createdAt && format(new Date(modalInfo.createdAt), "dd.LL. HH:mm")}</DateSC>
          </DateWrapperSC>
        </InfoWrapperSC>
        <DescriptionSC>{modalInfo.description}</DescriptionSC>
      </ContentModalSC>
    </Modal>
  );
};

export default ReleaseInformationModal;
