import styled from "styled-components";
import hourGlassSVG from "../../assets/icons/hourglass.svg";
import completedSVG from "../../assets/icons/checkmark.svg";

export const TaskWrapperSC = styled.div`
  display: flex;
  margin-top: 2px;
  padding: 4px;
  padding-left: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.neutral1};
    width: 100%;
    max-width: 60px;
  }
  .hourglass-icon {
    width: 100%;
    height: 40px;
    -webkit-mask: url(${hourGlassSVG}) no-repeat center;
    mask: url(${hourGlassSVG}) no-repeat center;
    background: ${({ theme }) => theme.primary};
    border: 1px solid blue !important;
  }
  .completed-icon {
    width: 100%;
    height: 40px;
    -webkit-mask: url(${completedSVG}) no-repeat center;
    mask: url(${completedSVG}) no-repeat center;
    background: #78bb6c;
  }
  .info {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      h2 {
        font-size: 12px;
        margin-left: 4px;
        color: ${({ theme }) => theme.neutral4};
      }
      p {
        font-size: 11px;
        color: ${({ theme }) => theme.neutral2};
      }
    }
    .description {
      p {
        margin-left: 4px;
        font-size: 13px;
        font-family: ${({ theme }) => theme.fontSub};
        color: ${({ theme }) => theme.neutral3};
      }
    }
  }
`;
