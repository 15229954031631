import styled from "styled-components";

export const UpcomingWrapperSC = styled.div`
  display: flex;
  margin-top: 2px;
  padding: 4px;
  padding-left: 0px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.neutral1};
    width: 100%;
    max-width: 64px;
  }
  .dota-icon {
    width: 40px;
    height: 40px;
    background-image: dotaSVG, none;
  }
  .info {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      h2 {
        font-size: 14px;
        font-weight: 400;
        margin-left: 4px;
        color: ${({ theme }) => theme.neutral4};
      }
      p {
        font-size: 14px;
        color: ${({ theme }) => theme.neutral3};
      }
    }

    .team-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
      .team-info {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        margin-left: 8px;
        .team-name {
          font-size: 12px;
          color: ${({ theme }) => theme.neutral3};
        }
        .team-score {
          font-size: 12px;
          color: ${({ theme }) => theme.neutral4};
          flex: 1;
          text-align: end;
        }
      }
    }
  }
`;
