import { useState } from "react";
import Modal from "../Modal";
import Checkbox from "../Checkbox";
import {
  AuthTypeTextSC,
  BottomInnerWrapperSC,
  BottomWrapperSC,
  ButtonSC,
  ContentSC,
  ImgSC,
  InputWrapperSC,
  LabelSC,
  TextErrorSC,
  TextFieldSC,
  TextSecondarySC,
  TitleSC,
} from "./styled";
import { registerSchema } from "./schemaRegister";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import AuthBackground from "../../assets/icons/auth-background.jpg";
import { useAuthStore } from "../../stores/auth";
import { toast } from "react-toastify";
import { useModalStore } from "../../stores/modal";
import { useTranslation } from "react-i18next";

const ModalRegister = () => {
  const { t } = useTranslation();

  const { userRegister, authErrors, clearAuthErrors } = useAuthStore();
  const { modal, setModal } = useModalStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues: { name: "", email: "", password: "", termsOfService: false },
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleLogin = () => {
    setIsChecked(false);
    reset();
    setModal("login");
  };

  const onSubmit = async (data) => {
    const { name, email, password } = data;
    const result = await userRegister(name, email, password);
    if (result?.data?.code === "P2002") {
      toast.error(t("toast-error-register"));
    } else if (result?.success) {
      toast.success(t("toast-success-register"));
      reset();
      setIsChecked(false);
      setModal("");
    }
  };

  return (
    <Modal
      open={modal === "register"}
      setIsOpen={() => {
        reset();
        setIsChecked(false);
        setModal("");
        clearAuthErrors();
      }}>
      <ImgSC src={AuthBackground} alt="" />
      <ContentSC>
        <TitleSC>{t("get-started")}</TitleSC>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapperSC>
            <TextFieldSC id="name" label="Name" variant="standard" {...register("name")} />
            <TextErrorSC>
              {errors.name?.message === "name-required"
                ? t("error-name-required")
                : errors.name?.message === "name-min-chars" && t("error-name-min-chars")}
            </TextErrorSC>
          </InputWrapperSC>
          <InputWrapperSC>
            <TextFieldSC id="email" name="email" label="Email" variant="standard" {...register("email")} />
            <TextErrorSC>
              {errors.email?.message === "mail-format"
                ? t("error-mail-format")
                : errors.email?.message === "mail-required" && t("error-mail-required")}
            </TextErrorSC>
          </InputWrapperSC>
          <InputWrapperSC>
            <TextFieldSC type="password" id="password" label="Password" variant="standard" {...register("password")} />
            <TextErrorSC>
              {errors.password?.message === "password-required"
                ? t("error-password-required")
                : errors.password?.message === "password-format" && t("error-password-format")}
            </TextErrorSC>
          </InputWrapperSC>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <LabelSC checked={isChecked} name="termsOfService">
                <Checkbox
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked((prev) => !prev);
                    onChange(!isChecked);
                  }}
                />
                <span style={{ marginLeft: 8 }}>
                  {t("i-agree-with-the")} <span className="highlight">{t("terms-of-service")}</span> {t("and")}{" "}
                  <span className="highlight">{t("privacy-policy")}</span>
                </span>
              </LabelSC>
            )}
            name="termsOfService"
            control={control}
          />
          <TextErrorSC>{errors.termsOfService?.message === "terms-required" && t("error-terms-required")}</TextErrorSC>
          <TextErrorSC style={{ textAlign: "center", marginTop: "20px" }}>
            {authErrors === "User already exists" && t("toast-error-register")}
          </TextErrorSC>
          <BottomWrapperSC>
            <BottomInnerWrapperSC>
              <TextSecondarySC>{t("sign-up")}</TextSecondarySC>
              <AuthTypeTextSC onClick={handleLogin}>{t("log-in")}</AuthTypeTextSC>
            </BottomInnerWrapperSC>
            <ButtonSC>
              <ArrowRightIcon />
            </ButtonSC>
          </BottomWrapperSC>
        </form>
      </ContentSC>
    </Modal>
  );
};

export default ModalRegister;
