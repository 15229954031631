import styled from "styled-components";

const WrapperSC = styled.div`
  border-radius: 2px;
  overflow: hidden;
  @media (max-width: 1023px) {
    max-width: unset;
  }
`;

const DiscordFeed = ({ theme }) => {
  return (
    <WrapperSC>
      <iframe
        title="discord-feed"
        src={
          theme === "light"
            ? "https://discord.com/widget?id=1030413847363862559&theme=light"
            : "https://discord.com/widget?id=1030413847363862559&theme=dark"
        }
        allowtransparency="true"
        width={"100%"}
        height="460px"
        frameBorder="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
    </WrapperSC>
  );
};

export default DiscordFeed;
