import styled from "styled-components";

export const InformationWrapperSC = styled.div`
  display: flex;
  margin-top: 2px;
  padding: 4px;
  padding-left: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 80px;
    max-height: 80px;
    padding: 10px;
    background-color: ${({ theme }) => theme.neutral1};
  }
  .info {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      h2 {
        font-size: 12px;
        margin-left: 4px;
        color: ${({ theme }) => theme.neutral4};
      }
      p {
        font-size: 11px;
        color: ${({ theme }) => theme.neutral3};
      }
    }
    .description {
      p {
        margin-left: 4px;
        font-size: 13px;
        font-family: ${({ theme }) => theme.fontSub};
        color: ${({ theme }) => theme.neutral3};
      }
    }
  }
`;

export const ContentModalSC = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 30px 30px 60px 30px;
  height: auto;
  border-radius: 20px;
  background: ${({ theme }) => theme.body};
  display: flex;
  flex-direction: column;
  position: relative;
  top: -30px;
`;

export const TitleSC = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: ${({ theme }) => theme.primary};
  margin: 20px 0;
`;

export const InfoWrapperSC = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InfoTitleSC = styled.h1`
  font-weight: 300;
  font-size: 22px;
  color: ${({ theme }) => theme.neutral4};
  flex-wrap: wrap;
  padding-left: 8px;
`;

export const DateWrapperSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const DateSC = styled.h1`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutral2};
  font-family: ${({ theme }) => theme.fontSub};
  margin-top: 6px;
  max-height: 162px;
  overflow-y: scroll;
  padding-left: 12px;
  height: 100%;
`;

export const DescriptionSC = styled.h1`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutral3};
  font-family: ${({ theme }) => theme.fontSub};
  margin-top: 6px;
  max-height: 162px;
  overflow-y: scroll;
  padding-left: 12px;
`;
