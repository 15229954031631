import create from "zustand";

export const useVoteLogsStore = create((set) => ({
  voteLogsFromFollowers: null,
  voteLogsFromTopUsers: null,
  matchVoteLogs: null,

  getMatchVoteLogs: async (isLoggedIn, match) => {
    if (!isLoggedIn) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vote/${match.id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      let data = await response.json();

      if (response.ok) {
        set({ matchVoteLogs: data });
      }
    } catch (error) {
      console.log("error fetching logs:", error);
    }
  },
  getVoteLogsFromFollowers: async (isLoggedIn, token, match) => {
    if (!isLoggedIn) {
      return;
    }
    try {
      if (!match) return;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vote/from-followers/${match.id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let data = await response.json();
      if (response.ok) {
        set({ voteLogsFromFollowers: data });
      }
    } catch (error) {
      console.log("error fetching logs:", error);
    }
  },

  getVoteLogsFromTopUsers: async (isLoggedIn, token, match) => {
    if (!isLoggedIn) {
      return;
    }
    try {
      if (!match) return;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/vote/from-top-users/${match.id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let data = await response.json();
      if (response.ok) {
        set({ voteLogsFromTopUsers: data });
      }
    } catch (error) {
      console.log("error fetching logs:", error);
    }
  },
}));
