import styled from "styled-components";

export const WrapperSC = styled.div`
  display: flex;
`;
export const ContentWrapperSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
export const MainWrapperSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;
export const LeftWrapperSC = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  flex-basis: 457px;
`;
export const RightWrapperSC = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-basis: 457px;
  gap: 20px;
`;
