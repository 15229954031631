import styled from "styled-components";
import { Menu, AppBar } from "@mui/material";

import MoonIconSvg from "../../assets/icons/moon-icon.svg";
import NotificationIconSvg from "../../assets/icons/notification-icon.svg";
import ThunderIconSvg from "../../assets/icons/thunder-icon.svg";
import ArrowDropdownSvg from "../../assets/icons/arrow-dropdown.svg";
import HamburgerSvg from "../../assets/icons/hamburger-menu.svg";
// import CookieIconSvg from "../../assets/icons/cookie-icon.svg";

export const NavSC = styled(AppBar)`
  &.MuiAppBar-root {
    width: 100%;
    height: 80px;
    background-color: ${({ theme }) => theme.body};
    z-index: 1000;
    box-shadow: none;
  }

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
  }
`;

export const NavItemsSC = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;

  a {
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.fontOrbitron};
    &:first-of-type {
      margin-right: 60px;

      @media (max-width: 1200px) {
        margin-right: 20px;
      }
    }
  }
`;

export const NavItemSC = styled.li`
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutral4};
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const MobileNavWrapperSC = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
`;

export const MenuLeaderboardSC = styled(Menu)`
  z-index: 1000;

  .MuiMenu-paper {
    border-radius: 20px;
    border: ${({ theme }) => `1px solid ${theme.neutral1}`};
    background-color: ${({ theme }) => theme.body};
    margin-top: 10px;
    padding: 16px 16px;
  }

  .leaderboard-title {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .players-list {
    .player-item {
      display: flex;
      align-items: center;
      height: 15px;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }

  .rank {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary};
    text-transform: uppercase;
    margin-right: 10px;
    min-width: 12px;
  }

  .player-name {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.neutral4};
    text-transform: uppercase;
    margin-right: auto;
  }

  .buffs {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary};
    text-transform: uppercase;
    text-align: right;
    margin-left: 20px;
  }

  .show-all {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    a {
      font-size: 12px;
      font-weight: 500;
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
    }
  }
`;

export const SettingsSC = styled.div`
  display: flex;
  align-items: center;
`;

export const LanguageButtonSC = styled.button`
  margin-right: 24px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon {
    width: 10px;
    height: 6px;
    display: inline-block;
    -webkit-mask: url(${ArrowDropdownSvg}) no-repeat center;
    mask: url(${ArrowDropdownSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.neutral4};
    margin-right: 8px;
    transform: ${({ active }) => active && "rotate(180deg)"};
  }

  .text {
    color: ${({ theme }) => theme.neutral4};
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontOrbitron};
  }
`;

export const MenuLanguagesSC = styled(Menu)`
  z-index: 1000;

  .MuiMenu-paper {
    border-radius: 10px;
    border: ${({ theme }) => `1px solid ${theme.neutral1}`};
    background-color: ${({ theme }) => theme.body};
    margin-top: 10px;
    padding: 0px 12px;
  }

  .languages-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const LanguageItemButtonSC = styled.button`
  background-color: transparent;
  border: ${({ theme }) => `1px solid ${theme.neutral1}`};
  color: ${({ theme }) => theme.neutral4};
  text-transform: uppercase;
  height: 24px;
  min-width: 40px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.primary}`};
    color: ${({ theme }) => theme.primary};
  }
`;

export const LanguagesWrapperSC = styled.div``;

export const ThemeTogglerWrapperSC = styled.div`
  display: flex;
  align-items: center;

  p {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontOrbitron};
    color: ${({ theme }) => theme.neutral4};
    margin-right: 8px;
  }

  @media (max-width: 1023px) {
    flex-direction: row-reverse;

    p {
      margin-left: 8px;
    }
  }
`;

export const ThemeTogglerBackgroundSC = styled.div`
  height: 24px;
  width: 40px;
  border-radius: 50px;
  position: relative;
  background-color: ${({ theme }) => theme.neutral4};
  cursor: pointer;
`;

export const ThemeTogglerDotSC = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.neutral1};
`;

export const MoonIconSC = styled.div`
  position: absolute;
  width: 16px;
  height: 20px;
  top: 2px;
  left: 2px;
  -webkit-mask: url(${MoonIconSvg}) no-repeat center;
  mask: url(${MoonIconSvg}) no-repeat center;
  background-color: ${({ theme }) => theme.body};
`;

export const AuthSectionSC = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LoginButtonSC = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fontOrbitron};
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

export const RegisterButtonSC = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fontOrbitron};
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const SimpleButtonSC = styled.button`
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontOrbitron};
  color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  @media (max-width: 1023px) {
    padding: 0;
  }
`;

export const NotificationIconWrapperSC = styled.div`
  position: relative;
  cursor: pointer;
`;

export const NotificationIconSC = styled.div`
  width: 19px;
  height: 20px;
  -webkit-mask: url(${NotificationIconSvg}) no-repeat center;
  mask: url(${NotificationIconSvg}) no-repeat center;
  background-color: ${({ theme }) => theme.neutral4};
`;

export const NotificationDotSC = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  border: ${({ theme }) => `1px solid ${theme.body}`};
  background-color: ${({ theme }) => theme.primary};
`;

export const BudgetSC = styled.div`
  width: fit-content;
  height: 32px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50px;
  margin-left: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    margin-left: 0;
    margin-bottom: 32px;
  }
`;

export const CookieIconWrapperSC = styled.div`
  width: 28px;
  height: 28px;
  color: white;
  border-radius: 50px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const CookieIconSC = styled.div`
  width: 24px;
  height: 10px;
  -webkit-mask: url(${ThunderIconSvg}) no-repeat center;
  mask: url(${ThunderIconSvg}) no-repeat center;
  background-color: ${({ theme }) => theme.primary};
`;

export const BudgetAmountSC = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 0 12px;
`;

export const VerticalDividerSC = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.neutral1};
  margin: 0 20px;
`;

export const AvatarSC = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.body};
    border: ${({ theme }) => `2px solid ${theme.primary}`};
    border-radius: 50px;
    object-fit: cover;
  }
`;

export const MenuAccountSC = styled(Menu)`
  z-index: 1000;

  .MuiMenu-paper {
    border-radius: 10px;
    border: ${({ theme }) => `1px solid ${theme.neutral1}`};
    background-color: ${({ theme }) => theme.body};
    margin-top: 10px;
    padding: 0px 4px;
  }

  .languages-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const HamburgerButtonSC = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  -webkit-mask: url(${HamburgerSvg}) no-repeat center;
  mask: url(${HamburgerSvg}) no-repeat center;
  background-color: ${({ theme }) => theme.neutral3};
  transform: scale(0.7);
`;

export const DrawerContentSC = styled.div`
  background-color: ${({ theme }) => theme.body};
  width: 100%;
  height: 100vh;
`;

export const NavItemsMobileSC = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 24px;
  margin-right: 24px;

  > * {
    margin-bottom: 16px;

    li {
      margin-right: 0;
    }
  }
`;

export const SettingsMobileSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  margin-left: 24px;
  margin-right: 24px;
  min-width: 160px;

  p.title-settings {
    color: ${({ theme }) => theme.neutral3};
    font-size: 16px;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontMain};
    margin-bottom: 10px;
  }

  .languages-mobile {
    display: flex;
    margin-bottom: 32px;
    gap: 8px;
  }

  .theme-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
`;
