import styled from "styled-components";
import { TableBody } from "@mui/material";

export const TitleSC = styled.h1`
  font-size: 36px;
  font-weight: 700;

  text-transform: uppercase;
  line-height: 45px;
  padding-top: 105px;
  padding-bottom: 64px;
`;

export const TableSectionSC = styled.div`
  padding-bottom: 24px;

  .MuiTableHead-root {
    .MuiTableCell-root {
      font-size: 12px;
      line-height: 44px;
      color: ${({ theme }) => theme.neutral4};
      text-transform: uppercase;
      padding: 0;
      padding-right: 8px;
      border-bottom: ${({ theme }) => `1px solid ${theme.neutral1}`};
      white-space: nowrap;
    }
  }
`;

export const TableBodySC = styled(TableBody)`
  .MuiTableRow-root {
    cursor: pointer;

    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 8px;

      .teams {
        order: -2;
        justify-content: center;
      }
    }
  }

  .MuiTableCell-root {
    height: 44px;
    font-size: 12px;
    color: ${({ theme }) => theme.neutral4};
    padding: 0;
    padding-right: 8px;
    border-bottom: 0;
    white-space: nowrap;
    @media (max-width: 1023px) {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.teams {
      display: flex;

      @media (max-width: 1023px) {
        background-color: ${({ theme }) => theme.neutral1};
      }
    }
  }
  .tournament {
    div {
      font-size: 14px;
    }
  }
  .date {
    div {
      font-size: 14px;
    }
  }
  .title div,
  .format div {
    font-size: 14px;
    text-transform: uppercase;
  }

  .team1,
  .team2 {
    display: flex;
    align-items: center;
    min-width: 220px;

    @media (max-width: 600px) {
      min-width: 145px;
    }

    .icon {
      display: flex;
      align-items: center;

      border-radius: 100px;
      padding: 2px 0;
      margin: 0 10px;
    }

    .icon img {
      width: 24px;
      height: 24px;

      object-fit: cover;
      margin: 0 16px;

      @media (max-width: 600px) {
        margin: 0 4px;
      }
    }
  }

  .team1 {
    font-size: 14px;
    justify-content: flex-end;
  }

  .team2 {
    font-size: 14px;
    justify-content: flex-start;
  }

  .vs {
    font-size: 14px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
