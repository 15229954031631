import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";

import { useAuthStore } from "../../stores/auth";
import { useLanguageStore } from "../../stores/language";
import { useModalStore } from "../../stores/modal";

import { ContainerSC } from "../commonSC";

import ThemeToggler from "./ThemeToggler";
import Drawer from "../Drawer";

import { NoIconSC } from "../commonSC";
import logoSVG from "../../assets/icons/logo.svg";

import {
  AuthSectionSC,
  AvatarSC,
  BudgetAmountSC,
  BudgetSC,
  CookieIconSC,
  CookieIconWrapperSC,
  DrawerContentSC,
  HamburgerButtonSC,
  LanguageButtonSC,
  LanguageItemButtonSC,
  LoginButtonSC,
  SimpleButtonSC,
  MenuAccountSC,
  MenuLanguagesSC,
  NavItemSC,
  NavItemsMobileSC,
  NavItemsSC,
  NavSC,
  RegisterButtonSC,
  SettingsMobileSC,
  SettingsSC,
  VerticalDividerSC,
  MobileNavWrapperSC,
} from "./styled";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const AppNav = ({ theme, toggleTheme }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerAccountOpen, setDrawerAccountOpen] = useState(false);

  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [anchorElAccount, setAnchorElAccount] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches1024Up = useMediaQuery("(min-width:1024px)");

  const { isLoggedIn, userLogout, userDetails } = useAuthStore();
  const { languages, language, setLanguage } = useLanguageStore();
  const { setModal } = useModalStore();

  const handleMyProfile = () => {
    navigate(`/user/${userDetails.id}`);
    handleCloseAccount();
    setDrawerAccountOpen(false);
  };

  const handleClickLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const handleClickAccount = (event) => {
    setAnchorElAccount(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  function openModalLogin() {
    setModal("login");
  }

  function openModalRegister() {
    setModal("register");
  }

  // prevent mui autoopen menu bug
  useEffect(() => {
    if (isLoggedIn) {
      setAnchorElAccount(null);
    }
  }, [isLoggedIn]);

  return (
    <>
      <NavSC>
        <ContainerSC>
          {matches1024Up && (
            <NavItemsSC>
              <Link to="/">
                <img src={logoSVG} alt="logo" />
              </Link>
              <Link to="/">
                <NavItemSC>{t("home-page")}</NavItemSC>
              </Link>
              <Link to="/leaderboard">
                <NavItemSC>{t("leaderboard-page")}</NavItemSC>
              </Link>
              <Link to="/schedule">
                <NavItemSC>{t("schedule")}</NavItemSC>
              </Link>
              <Link to="/how-to-play">
                <NavItemSC>{t("how-to-play")}</NavItemSC>
              </Link>
            </NavItemsSC>
          )}
          {!matches1024Up && (
            <>
              <MobileNavWrapperSC>
                <HamburgerButtonSC onClick={() => setDrawerOpen(true)} />
                <Link to="/">
                  <img src={logoSVG} alt="logo" />
                </Link>
                <Drawer
                  breakpoint={!matches1024Up}
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                  anchor="left">
                  <DrawerContentSC>
                    <NavItemsMobileSC>
                      <Link to="/" onClick={() => setDrawerOpen(false)}>
                        <NavItemSC>{t("home-page")}</NavItemSC>
                      </Link>
                      <Link to="/leaderboard" onClick={() => setDrawerOpen(false)}>
                        <NavItemSC>{t("leaderboard-page")}</NavItemSC>
                      </Link>
                      <Link to="/schedule" onClick={() => setDrawerOpen(false)}>
                        <NavItemSC>{t("schedule")}</NavItemSC>
                      </Link>
                      <Link to="/how-to-play" onClick={() => setDrawerOpen(false)}>
                        <NavItemSC>{t("how-to-play")}</NavItemSC>
                      </Link>
                    </NavItemsMobileSC>
                  </DrawerContentSC>
                </Drawer>
              </MobileNavWrapperSC>
            </>
          )}

          {matches1024Up && (
            <SettingsSC>
              <LanguageButtonSC onClick={handleClickLanguage} active={Boolean(anchorElLanguage)}>
                <span className="icon" />
                <span className="text">{language}</span>
              </LanguageButtonSC>
              <MenuLanguagesSC
                anchorEl={anchorElLanguage}
                open={Boolean(anchorElLanguage)}
                onClose={handleCloseLanguage}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}>
                <div className="languages-wrapper">
                  {languages.map((lang) => {
                    return (
                      lang !== language && (
                        <LanguageItemButtonSC
                          className="language-item"
                          key={lang}
                          onClick={() => {
                            i18next.changeLanguage(lang);
                            setLanguage(lang);
                            setAnchorElLanguage(null);
                          }}>
                          {lang}
                        </LanguageItemButtonSC>
                      )
                    );
                  })}
                </div>
              </MenuLanguagesSC>
              <ThemeToggler theme={theme} toggleTheme={toggleTheme} />
              <AuthSectionSC>
                {!isLoggedIn ? (
                  <>
                    <LoginButtonSC onClick={openModalLogin}>{t("log-in")}</LoginButtonSC>
                    <RegisterButtonSC onClick={openModalRegister}>{t("sign-up")}</RegisterButtonSC>
                  </>
                ) : (
                  <>
                    {/* <NotificationIconWrapperSC>
                      <NotificationIconSC />
                      <NotificationDotSC />
                    </NotificationIconWrapperSC> */}
                    <BudgetSC>
                      <CookieIconWrapperSC>
                        <CookieIconSC />
                      </CookieIconWrapperSC>
                      <BudgetAmountSC>{userDetails.buffs}</BudgetAmountSC>
                    </BudgetSC>
                    <VerticalDividerSC />
                    <AvatarSC onClick={(e) => handleClickAccount(e)}>
                      {userDetails?.avatar ? (
                        <img src={require(`../../assets/icons/hero-avatars/${userDetails.avatar}`)} alt="Avatar" />
                      ) : (
                        <NoIconSC size={32}>
                          <div className="no-icon" />
                        </NoIconSC>
                      )}
                    </AvatarSC>
                    <MenuAccountSC
                      anchorEl={anchorElAccount}
                      open={Boolean(anchorElAccount)}
                      onClose={handleCloseAccount}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}>
                      <SimpleButtonSC onClick={handleMyProfile}>{t("my-profile")}</SimpleButtonSC>
                      <SimpleButtonSC
                        onClick={() => {
                          userLogout();
                        }}>
                        {t("log-out")}
                      </SimpleButtonSC>
                    </MenuAccountSC>
                  </>
                )}
              </AuthSectionSC>
            </SettingsSC>
          )}

          {!matches1024Up && (
            <SettingsSC>
              {/* {isLoggedIn && (
                <NotificationIconWrapperSC style={{ marginRight: "24px" }}>
                  <NotificationIconSC />
                  <NotificationDotSC />
                </NotificationIconWrapperSC>
              )} */}
              <AvatarSC onClick={() => setDrawerAccountOpen(true)}>
                {userDetails?.avatar ? (
                  <img src={require(`../../assets/icons/hero-avatars/${userDetails.avatar}`)} alt="Avatar" />
                ) : (
                  <NoIconSC size={32}>
                    <div className="no-icon" />
                  </NoIconSC>
                )}
              </AvatarSC>
              <Drawer
                breakpoint={!matches1024Up}
                open={drawerAccountOpen}
                onClose={() => setDrawerAccountOpen(false)}
                anchor="right">
                <DrawerContentSC>
                  <SettingsMobileSC>
                    <p className="title-settings">Language</p>
                    <div className="languages-mobile">
                      {languages.map((lang) => {
                        return (
                          lang !== language && (
                            <LanguageItemButtonSC
                              key={lang}
                              onClick={() => {
                                i18next.changeLanguage(lang);
                                setLanguage(lang);
                                setAnchorElLanguage(null);
                              }}>
                              {lang}
                            </LanguageItemButtonSC>
                          )
                        );
                      })}
                    </div>

                    <p className="title-settings">{t("theme")}</p>
                    <div className="theme-wrapper">
                      <ThemeToggler theme={theme} toggleTheme={toggleTheme} />
                    </div>

                    {!isLoggedIn ? (
                      <>
                        <>
                          <LoginButtonSC
                            onClick={() => {
                              openModalLogin();
                              setDrawerAccountOpen(false);
                            }}>
                            {t("log-in")}
                          </LoginButtonSC>
                          <RegisterButtonSC
                            onClick={() => {
                              openModalRegister();
                              setDrawerAccountOpen(false);
                            }}>
                            {t("sign-up")}
                          </RegisterButtonSC>
                        </>
                      </>
                    ) : (
                      <>
                        <BudgetSC>
                          <CookieIconWrapperSC>
                            <CookieIconSC />
                          </CookieIconWrapperSC>
                          <BudgetAmountSC>{userDetails.buffs}</BudgetAmountSC>
                        </BudgetSC>
                        <SimpleButtonSC onClick={handleMyProfile}>{t("my-profile")}</SimpleButtonSC>
                        <SimpleButtonSC
                          onClick={() => {
                            userLogout();
                            setDrawerAccountOpen(false);
                          }}>
                          {t("log-out")}
                        </SimpleButtonSC>
                      </>
                    )}
                  </SettingsMobileSC>
                </DrawerContentSC>
              </Drawer>
            </SettingsSC>
          )}
        </ContainerSC>
      </NavSC>
    </>
  );
};

export default AppNav;
