import Modal from "../Modal";
import {
  BottomWrapperSC,
  ButtonSC,
  ContentSC,
  ImgSC,
  InputWrapperSC,
  TextErrorSC,
  TextFieldSC,
  TitleSC,
} from "./styled";
import { forgotPasswordSchema } from "./schemaForgotPassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import AuthBackground from "../../assets/icons/auth-background.jpg";
import { useAuthStore } from "../../stores/auth";
import { toast } from "react-toastify";
import { useModalStore } from "../../stores/modal";
import { useTranslation } from "react-i18next";

const ModalForgotPassword = () => {
  const { t } = useTranslation();

  const { forgotPassword, clearAuthErrors, authErrors } = useAuthStore();
  const { modal, setModal } = useModalStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: { email: "" },
  });

  const onSubmit = async (data) => {
    const { email } = data;
    const result = await forgotPassword(email);
    if (result) {
      reset();
      toast.success(t("toast-success-forgot-password"));
      clearAuthErrors();
      setModal("");
    }
  };

  return (
    <Modal
      open={modal === "forgot-password"}
      setIsOpen={() => {
        reset();
        setModal("");
        clearAuthErrors();
      }}>
      <ImgSC src={AuthBackground} alt="" />
      <ContentSC>
        <TitleSC style={{ textAlign: "center" }}>{t("forgot-password")}</TitleSC>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapperSC>
            <TextFieldSC id="email" name="email" label="Email" variant="standard" {...register("email")} />
            <TextErrorSC>
              {errors.email?.message === "mail-format"
                ? t("error-mail-format")
                : errors.email?.message === "mail-required" && t("error-mail-required")}
            </TextErrorSC>
          </InputWrapperSC>
          <TextErrorSC style={{ textAlign: "center", marginTop: "20px" }}>
            {authErrors === "User not found" && t("toast-error-user-not-found")}
          </TextErrorSC>
          <BottomWrapperSC style={{ justifyContent: "flex-end" }}>
            <ButtonSC>
              <ArrowRightIcon />
            </ButtonSC>
          </BottomWrapperSC>
        </form>
      </ContentSC>
    </Modal>
  );
};

export default ModalForgotPassword;
